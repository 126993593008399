import Navbar from "../../components/Appbar";
import { Box, Card, Paper, Typography } from "@mui/material";
import MiniDrawer from "../../components/draw";
import { TableContainer } from "@mui/material";
import { TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { Skeleton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { filteretats } from "../../data/vol_data";
import Reserevation_dialoge from "../../model/reservation/pack_vols/reservation_pack_model";
import SuccesDialoge from "../../model/succes";
import {
  Get_info_reservation,
  Get_info_reservation_update,
  Get_pack_filter,
  Get_resrevation,
  New_reservation,
  Update_resrvation,
  delete_reservation,
} from "../../store/commerciel/reservation_slice";
import { useDispatch, useSelector } from "react-redux";
import ErreurDialoge from "../../model/Erreur";
import BackLoading from "../page_tous/back_loading";
import DeleteConfirmationDialog from "../../model/DeleteConfirme";
import Info_reservation from "../../model/reservation/pack_vols/reservation_pack_info";
import Reserevation_dialoge_update from "../../model/reservation/pack_vols/update_reservation_pack";
import Cookies from "js-cookie";
import Reserevation_dialoge_pack_omra from "../../model/reservation/pack_omra/resrvation_pack_omra";
import { GetPacksReserveOmra, addReservationPack_omra, deleteResrevationPackOmra } from "../../store/commerciel/reservation_omra _slice";
export default function Reserve_pack_omra() {
  const dispatch = useDispatch();
  // paginate

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  // ajouter

  const [Ajouter, setAjouter] = useState(false);
  const [AjouterSucces, setAjouterSucces] = useState(false);
  const [AjouterErreur, SetAjouterErreur] = useState(false);
  // get donne de filter et resrvation
  const { loading } = useSelector((state) => state.reservation);
  const  resrevation  = useSelector((state) => state.resrvation_omra.packsReserve)
  const { packs_filter } = useSelector((state) => state.reservation);
  const [select_pack_filter, SetSelect_pack_filter] = useState();
  const [select_etat_filter, SetSelect_etat_filter] = useState();
  const [nom_filter, SetNomFilter] = useState("");
  const [filterDate_expiration, setFilterDateExpiration] = useState(false);
  const [filterSociete, SetFilterSociete] = useState("");
  const filterEtat = filteretats;
 console.log(resrevation)
  // delete resevation

  const [delete_dialog, setDelete_dialog] = useState(false);
  const [id_delete, setId_delete] = useState();
  const [delete_succes, setDelete_succes] = useState(false);
  const [delete_error, SetDelete_error] = useState(false);

  // info reservation

  const [info_reservation, setInfo_reservation] = useState(false);

  // update resrvation
  const [dialoge_update, SetDialoge_update] = useState(false);
  const [Id_update, setId_update] = useState();
  const [succes_update, SetSucessUpdate] = useState(false);
  const [Error_update, SetErrorUpdate] = useState(false);
  const [message_erreur_update, setMessageErrorUpdate] = useState();

  useEffect(() => {
    get_reserv();
  }, [
    page,
    rowsPerPage,
    select_etat_filter,
    select_pack_filter,
    nom_filter,
    filterDate_expiration,
    filterSociete,
  ]);

  useEffect(() => {
    dispatch(Get_pack_filter());
  }, []);

  /// get les resrvation filtere
  function get_reserv() {
    const data = {
      num: rowsPerPage,
      page: page,
      pack: select_pack_filter,
      etat: select_etat_filter,
      nom: nom_filter,
      societe: filterSociete,
      exp: filterDate_expiration,
    };
    dispatch(GetPacksReserveOmra(data));
  }

  //// pagination

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  ///// ajouter le resevation

  function ajouter_new_reservation(data) {
    setAjouter(false);
    dispatch(addReservationPack_omra(data)).then((result) => {
      if (addReservationPack_omra.fulfilled.match(result)) {
        setAjouterSucces(true);
      }
      if (addReservationPack_omra.rejected.match(result)) {
        SetAjouterErreur(true);
      }
    });
  }

  /// delete resrevation
  function supprimer_reservation() {
    setDelete_dialog(false);
    dispatch(deleteResrevationPackOmra(id_delete)).then((result) => {
      if (deleteResrevationPackOmra.fulfilled.match(result)) {
        setDelete_succes(true);
      }
      if (deleteResrevationPackOmra.rejected.match(result)) {
        SetDelete_error(true);
      }
      setId_delete();
    });
  }
  // get info
  function info(id_info) {
    dispatch(Get_info_reservation(id_info)).then((result) => {
      if (Get_info_reservation.fulfilled.match(result)) {
        setInfo_reservation(true);
      }
    });
  }
  /*
  function info_update(id){
    dispatch(Get_info_reservation_update(id)).then((result) => {
      if (Get_info_reservation_update.fulfilled.match(result)) {
        setId_update(id)
        SetDialoge_update(true)
      }
    });
  }

  function Update_reservation_(data){
    setId_update()
    SetDialoge_update(false)
      dispatch(Update_resrvation(data)).then((result)=>{
        if(Update_resrvation.fulfilled.match(result)){
               SetSucessUpdate(true)
        }
        if (Update_resrvation.rejected.match(result)) {
             SetErrorUpdate(true)
             if(result.error.message == 'Le nombre de places demandées est supérieur aux places disponibles dans le nouveau pack'){
                setMessageErrorUpdate("Le nombre de places demandées est supérieur aux places disponibles dans le nouveau pack")
             }else{
                setMessageErrorUpdate("la resrevation n'a pas mise a jour")
             }
      }
      })
  }
*/
  return (
    <div style={{ background: "#EEEEEE", minHeight: "100vh" }}>
      <Navbar />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <MiniDrawer />
        <Box
          component="main"
          sx={{
            marginTop: "7%",
            width: "100%",
          }}
        >
          <Box
            component="main"
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "3%",
              marginLeft: "3%",
            }}
          >
            <Card>
              <Paper>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" colSpan={3}>
                          <Typography variant="h4">
                            <b>Réservation omra</b>
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>

                        {/*  <TableCell>
                          <Autocomplete
                            id="pack"
                            openOnFocus
                            options={packs_filter}
                            getOptionLabel={(p) => p.nom}
                            onChange={(event, selectedOption, reason) => {
                              if (selectedOption) {
                                SetSelect_pack_filter(selectedOption.nom)
                                setPage(0);
                              }
                              if (reason === "clear") {
                                SetSelect_pack_filter()
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="pack"
                                variant="standard"
                                id="pack"
                              />
                            )}
                          />
                        </TableCell>*/}
                        <TableCell></TableCell>

                        <TableCell>
                          <Button
                            variant="contained"
                            color={
                              filterDate_expiration == true
                                ? "error"
                                : "success"
                            }
                            onClick={() => {
                              setFilterDateExpiration(!filterDate_expiration);
                            }}
                          >
                            expiration
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            onClick={() => {
                              setAjouter(true);
                            }}
                          >
                            +add
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>Nom</b>
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>societe</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>Telephone</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>Pack</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>Etat</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>date exp</b>
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>Action</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resrevation
                        ? resrevation?.data?.map((r) => (
                            <TableRow
                              key={r.id}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                            >
                              <TableCell align="center">
                                {r?.get_clients.nom_client}
                              </TableCell>
                              <TableCell align="center">{r?.get_clients.societe}</TableCell>
                              <TableCell align="center">
                                {r?.get_clients.telephone}
                              </TableCell>
                              <TableCell align="center">
                                {r?.get_packs?.nom_pack_omra}
                              </TableCell>
                              <TableCell align="center">{r?.etat}</TableCell>
                              <TableCell align="center">
                                {r?.date_expiration}
                              </TableCell>
                              <TableCell align="center">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <IconButton
                                    aria-label="info"
                                    color="secondary"
                                    size="small"
                                   // onClick={() => {
                                    //  info(r.id);
                                    //}}
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="update"
                                    color="primary"
                                    size="small"
                                    // onClick={()=>{
                                    //  info_update(r.id)
                                    //}}
                                  >
                                    <ModeEditOutlineIcon />
                                  </IconButton>
                                  {r.etat == "attende payement" ||
                                  r.etat == "reserve" ? (
                                    <IconButton
                                      aria-label="delete"
                                      size="small"
                                      color="error"
                                      onClick={() => {
                                        setDelete_dialog(true);
                                        setId_delete(r.id);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  ) : null}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))
                        : Array.from({ length: 6 }, (_, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell >
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <TablePagination
                rowsPerPageOptions={[5, 25, 100]}
                component="div"
                count={resrevation?.total ?? 1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Box>
        </Box>
      </Box>
      <Reserevation_dialoge_pack_omra
        open={Ajouter}
        onClose={() => {
          setAjouter(false);
        }}
        onlick={ajouter_new_reservation}
      />
      <SuccesDialoge
        open={AjouterSucces}
        onClose={() => {
          setAjouterSucces(false);
          get_reserv();
        }}
        content="le reservation ajouter avec succes"
      />
      <ErreurDialoge
        open={AjouterErreur}
        onClose={() => {
          SetAjouterErreur(false);
        }}
        content="Échec de l'ajout d'une réservation"
      />

      <DeleteConfirmationDialog
        open={delete_dialog}
        onClose={() => {
          setDelete_dialog(false);
        }}
        content="Êtes-vous sûr de vouloir supprimer ce Réservation ?"
        onConfirm={supprimer_reservation}
      />
      <SuccesDialoge
        open={delete_succes}
        onClose={() => {
          setDelete_succes(false);
          get_reserv();
        }}
        content="le reservation supprimer avec succes"
      />
      <ErreurDialoge
        open={delete_error}
        onClose={() => {
          SetDelete_error(false);
          get_reserv();
        }}
        content="réservation n'a pas été supprimer"
      />

      <Info_reservation
        open={info_reservation}
        onclose={() => {
          setInfo_reservation(false);
        }}
      />
      <BackLoading open={loading} />
    </div>
  );
}
