import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";

function FournisseurForm({
  open,
  onClose,
  onlick,
  sujet,
  choix,
  arr,
  d_update,
}) {
  const [nom, setNom] = useState("");
  const [type, settype] = useState("");
  const [adress, setAdress] = useState("");
  const [number, setNumber] = useState("");
  const [pays, setPays] = useState("");
  const [ville, setVille] = useState("");
  const [maps, setmaps] = useState("");
  const [email, setEmail] = useState("");
  const [desc, setdesc] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const { fournisseur } = useSelector((state) => state.fournisseur);

  const [errornom, seterrornom] = useState(false);
  const [errortype, seterrortype] = useState(false);
  const [errorpays, seterrorpays] = useState(false);
  const [errorville, seterrorVille] = useState(false);
  const [errormaps, seterrormaps] = useState(false);
  const [errornumber, seterrornumber] = useState(false);
  const [erroremail, setErroremail] = useState(false);
  const [errorFile, setErrorfile] = useState(false);
  const [errorDesc, SetErrorDesc] = useState(false);
  const [errorAdress , setErroradress] = useState(false)
  useEffect(() => {
    if (choix == "update") {
      settype(fournisseur?.type);
      setNom(fournisseur?.nom);
      setNumber(fournisseur?.number);
      setPays(fournisseur?.pays);
      setVille(fournisseur?.ville);
      setmaps(fournisseur?.maps);
      setEmail(fournisseur?.email);
      setdesc(fournisseur?.description);
      setAdress(fournisseur?.adress)
    } else {
      inisale_state();
    }
  }, [fournisseur]);

  function isEmailValid(email) {
    // Utilisez une expression régulière pour valider l'e-mail
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  function valide(e) {
    e.preventDefault();
    seterrornom(false);
    seterrortype(false);
    seterrornumber(false);
    seterrorVille(false);
    seterrorpays(false);
    seterrormaps(false);
    setErroremail(false);
    setErrorfile(false)
    setErroradress(false)

    if (nom.trim() === "") {
      seterrornom(true);
      return;
    }
    if (type.trim() === "") {
      seterrortype(true);
      return;
    }
    if (pays.trim() === "") {
      seterrorpays(true);
      return;
    }
    if (ville.trim() === "") {
      seterrorVille(true);
      return;
    }
    if (maps.trim() === "") {
      seterrormaps(true);
      return;
    }
    if (number.length < 10) {
      seterrornumber(true);
      return;
    }
    if (choix == "ajouter") {
      if (!selectedFile) {
        setErrorfile(" ajouter file");
   
        return;
      }
    }
    if (!isEmailValid(email)) {
      setErroremail(true);
      return;
    }
    if (desc.trim() === "") {
      SetErrorDesc(true);
      return;
    }
    console.log(e.target.file.files[0])
    const data = new FormData();
    data.append("name", nom);
    data.append("type", type);
    data.append("number", number);
    data.append("pays", pays);
    data.append("ville", ville);
    data.append("maps", maps);
    data.append("email", email);
    data.append("description", desc);
    data.append("adress" , adress)
    data.append("image", e.target.file.files[0]);
    if (choix == "update") {
      data.append("id", d_update);
    }

    onlick(data);
    inisale_state();
    setSelectedFile(null);
  }
  function inisale_state() {
    settype("");
    setNom("");
    setNumber("");
    setPays("");
    setVille("");
    setmaps("");
    setEmail("");
    setdesc("");
    setAdress("")
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle sx={{fontSize:"40px"}}>{sujet}</DialogTitle>
      <Box
      marginTop="7%"
        component="form"
        onSubmit={valide}
        encType="multipart/form-data"
        sx={{ width: "80%", marginLeft: "10%" }}
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        
          <Grid item xs={4}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Nom fournisseur"
            name="nom"
            value={nom}
            error={errornom}
            onChange={(e) => {
              setNom(e.target.value);
            }}
            autoComplete="Nom fournisseur"
            autoFocus
          />
          </Grid>
          <Grid item xs={4}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="number fournisseur"
            name="number"
            type="number"
            error={errornumber}
            value={number}
            onChange={(e) => {
              setNumber(e.target.value);
            }}
            autoComplete="number fournisseur"
            autoFocus
          />
         </Grid>
         <Grid item xs={4}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="email fournisseur"
            name="email"
            type="text"
            error={erroremail}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            autoComplete="email fournisseur"
            autoFocus
          />
       </Grid>
       <Grid item xs={4}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="type fournisseur"
            name="type"
            error={errortype}
            value={type}
            onChange={(e) => {
              settype(e.target.value);
            }}
            autoComplete="type fournisseur"
            autoFocus
          />
          </Grid>
          <Grid item xs={4}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="description fournisseur"
            name="description"
            error={errorDesc}
            value={desc}
            onChange={(e) => {
              setdesc(e.target.value);
            }}
            autoComplete="description fournisseur"
            autoFocus
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="adress fournisseur"
            name="adress"
            error={errorAdress}
            value={adress}
            onChange={(e) => {
              setAdress(e.target.value);
            }}
            autoComplete="adress fournisseur"
            autoFocus
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="pays"
            name="pays"
            error={errorpays}
            value={pays}
            onChange={(e) => {
              setPays(e.target.value);
            }}
            autoComplete="pays"
            autoFocus
          />
          </Grid>
          <Grid item xs={4}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="ville"
            name="ville"
            error={errorville}
            value={ville}
            onChange={(e) => {
              setVille(e.target.value);
            }}
            autoComplete="ville"
            autoFocus
          />
          </Grid>
          <Grid item xs={4}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="maps"
            name="maps"
            error={errormaps}
            value={maps}
            onChange={(e) => {
              setmaps(e.target.value);
            }}
            autoComplete="maps"
            autoFocus
          />
          </Grid>
          <Grid item xs={4}>
       
          
             <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
            sx={{ width: "100%" }}
          >
            Upload File
            <input
              name="file"
              type="file"
              // accept=".pdf,.jpg,.png"
              onChange={(e) => {
                setSelectedFile(e.target.files[0]);
              }}
              style={{ display: "none" }}
            />
          </Button>
          {selectedFile && <p>Selected File: {selectedFile.name}</p>}
          {!selectedFile && errorFile && (
            <p style={{ color: "red" }}>erreur : {errorFile}</p>
          )}
          </Grid>
        </Grid>
        <DialogActions >
          <Button
            onClick={() => {
              onClose();
              inisale_state();
            }}
          >
            Annuler
          </Button>
          <Button variant="contained" type="submit" color="primary">
            {choix}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default FournisseurForm;
