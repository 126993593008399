import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { auth, Auth_login, Auth_user } from "../store/Auth";
import CircularIndeterminate from "../pages/page_tous/loading";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import { updateAuthToken } from "../api/Api_token";

const defaultTheme = createTheme();

export default function SignIn() {
  const location = useLocation();
  const { state } = location;
  const { loading_auth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const Nav = useNavigate();
  const { t, i18n } = useTranslation();
  const [passwordError, SetPasswordError] = useState(false);
  const [emailError, setEmailError] = useState();
  const [Email, setEmail] = useState("");

  useEffect(() => {
    const userLanguage = navigator.language;

    switch (userLanguage) {
      case "fr-FR":
        i18n.changeLanguage("fr");
        break;
      case "fr":
        i18n.changeLanguage("fr");
        break;
      case "ar":
        i18n.changeLanguage("ar");
        break;
      case "en":
        i18n.changeLanguage("en");
        break;
      default:
        i18n.changeLanguage("en");
    }
  }, []);

  function isEmailValid(email) {
    // Utilisez une expression régulière pour valider l'e-mail
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;
    if (!isEmailValid(email)) {
      setEmailError(t("message_email"));

      return;
    }

    if (password.length < 8) {
      SetPasswordError(t("message_password"));

      return;
    }

    const data = {
      email: event.target.email.value,
      password: event.target.password.value,
    };

    dispatch(Auth_login(data)).then((result) => {
      if (Auth_login.fulfilled.match(result)) {
        switch (result.payload.message) {
          case "connected":
            const currentTime = new Date().getTime();
            const expirationTime = currentTime + 6 * 24 * 60 * 60*1000;
            const expirationDate = new Date(expirationTime);
            const expirationDateString = expirationDate.toISOString();
            Cookies.set("token_expiration", expirationDateString, {
              expires: new Date(expirationTime),
            });
            Cookies.set("token", result.payload.token, {
              expires: new Date(expirationTime),
            });
            Cookies.set("role",result.payload.user ,{
              expires: new Date(expirationTime),
            })
            dispatch(auth(result.payload.token));
            const tok = result.payload.token;
            updateAuthToken(tok);
            if (result.payload.user == "admin") { 
              Nav(`/admin`);
              break
            }
            if (result.payload.user == "commerciel") { 
              Nav(`/commerciel`);
              break
            }
            if(result.payload.user == "comptable"){
              Nav("/comptable")
              break
            }
            /*
            if(result.payload.user == "client"){
               Nav("/vols")
               break;
            }
              */
             break
          case "password incorrect":
            SetPasswordError(t("password incorrect"));
            break;
          case "email not valid":
            Nav("/auth/valid");
            break;
          case "user not found":
            setEmailError(t("email_incorrect"));
            break;
          default:
        }
      }
    });
  };

  if (loading_auth) {
    return (
      <>
        <CircularIndeterminate />
      </>
    );
  }
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("Se_connecter")}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={emailError}
              helperText={emailError}
              value={Email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={!!passwordError}
              helperText={passwordError || ""}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("Se_connecter")}
            </Button>
            <Grid container>
              <Grid item xs>
                {t("m_d_p_o")}
              </Grid>
              <Grid item>
                <Link to="/auth/register">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
