import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useState , useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Auth_validate } from "../store/Auth";
import CircularIndeterminate from "../pages/page_tous/loading";
import Cookies from "js-cookie";
import { auth } from "../store/Auth";
import { updateAuthToken } from "../api/Api_token";







const defaultTheme = createTheme();

export default function Validation() {
  const {loading_auth} = useSelector(state=>state.auth)
  const dispatch = useDispatch()
  const Nav = useNavigate()
  const [codeError, SetcodeError] = useState();
  const [emailError, setEmailError] = useState();
  const [email , setEmail]=useState("")
  const {t,i18n}=useTranslation()
  
  function isEmailValid(email) {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(email);
    }


  const handleSubmit = (event) => {
    event.preventDefault();

   const   email = event.target.mail.value
   const  code = event.target.code.value

    if (!isEmailValid(email)) {
      setEmailError(t("message_email"));
      return;
    }
    if (code.length < 6) {
      SetcodeError(t("message_code"));
      return;
    }

    const data = {
      email:email,
      code:code
    };
    dispatch(Auth_validate(data)).then((result)=>{
      if(Auth_validate.fulfilled.match(result)){

        if(result.payload.message =="connected"){
          const currentTime = new Date().getTime();
            const expirationTime = currentTime + 6 * 24 * 60 * 60;
            const expirationDate = new Date(expirationTime);
            const expirationDateString = expirationDate.toISOString();
            Cookies.set('token_expiration',expirationDateString, { expires: new Date(expirationTime) });
          Cookies.set("token", result.payload.token  , {expires : new Date(expirationTime)})
          Cookies.set("token", result.payload.token  , {expires : new Date(expirationTime)})
          Cookies.set("role",result.payload.user ,{
            expires: new Date(expirationTime),
          })
           dispatch(auth(result.payload.token ))
           const tok=result.payload.token
           updateAuthToken(tok)
           Nav(`/${result.payload.user}`)
        }else{
          SetcodeError(t("code_incorrect"))
        }
      }
    })

   
  };
  if(loading_auth){
    return (<><CircularIndeterminate/></>)
  }
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("validation")}
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            Validate
            sx={{ mt: 1 }}
          >
            <TextField
            error={emailError}
            helperText={emailError}
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("Email_Address")}
              name="mail"
              autoComplete="email"
              value={email}
              onChange={(e)=>{
                   setEmail(e.target.value)
              }}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("code_validation")}
              name="code"
              autoComplete="code"
              autoFocus
              error={codeError}
              helperText={codeError}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("valide")}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
