import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../api/Api_token";

export const get_pack_omra = createAsyncThunk("pack omra", async () => {
  const response = await instance.get("pack_omra/name");
  return response.data;
});

export const get_pack_omra_chamber = createAsyncThunk(
  "pack chmaber",
  async (id) => {
    const response = await instance.get(`get_pack_omra/chamber/${id}`);
    return response.data;
  }
);

export const addReservationPack_omra = createAsyncThunk(
  "add reservation",
  async (data) => {
    const response = await instance.post(
      `commerciel/resrevation/pack_omra/addReservation`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  }
);

export const GetPacksReserveOmra= createAsyncThunk("get pack omra resreve" , async(data)=>{
    const response = await instance.get(`commerciel/resrevation/pack_omra/getPacks/${data.num}?page=${data.page+1}`)
     return response.data
  })

  export const deleteResrevationPackOmra = createAsyncThunk("delete resrvation pack omra" , async(id)=>{
    const response = await instance.delete(`commerciel/resrevation/pack_omra/DeleteReservation/${id}`)
    return response.data
  })

const initialState = {
  loading: false,
  pack_omra: [],
  chamber: [],
  packsReserve:[]
};

export const Reservation_Omra = createSlice({
  name: "pack omra reservation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(get_pack_omra.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_pack_omra.fulfilled, (state, action) => {
      state.loading = false;
      state.pack_omra = action.payload;
    });
    builder.addCase(get_pack_omra.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(get_pack_omra_chamber.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_pack_omra_chamber.fulfilled, (state, action) => {
      state.loading = false;
      state.chamber = action.payload;
    });
    builder.addCase(get_pack_omra_chamber.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addReservationPack_omra.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addReservationPack_omra.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(addReservationPack_omra.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(GetPacksReserveOmra.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetPacksReserveOmra.fulfilled, (state, action) => {
      state.loading = false;
      state.packsReserve= action.payload;
    });
    builder.addCase(GetPacksReserveOmra.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteResrevationPackOmra.pending , (state , action)=>{
      state.loading = true
    })
    builder.addCase(deleteResrevationPackOmra.fulfilled , (state, action)=>{
    state.loading = false
  })
  builder.addCase (deleteResrevationPackOmra.rejected , (state , action)=>{
    state.loading = false
  })
  },
});

export default Reservation_Omra.reducer;
