import React from 'react';
import { Box, Button, Typography } from '@mui/material';

import { Link } from 'react-router-dom';

export default function Erreur_404() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'white',
      }}
    >
      <Typography variant="h1" style={{ color: 'black' }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: 'black' }}>
        La page que vous recherchez n'existe pas.
      </Typography>
      
    </Box>
  );
}

