import { useEffect, useState } from "react";
import Registre from "./auth/registre";
import Validation from "./auth/validation";
import SignIn from "./auth/login";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import HomeAdmin from "./pages/admin/HomeAdmin";
import Fournisseur from "./pages/admin/Fournisseur";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  Auth_logout,
  Auth_user,
  auth,
  deconnection,
  test_auth,
} from "./store/Auth";
import Token_expirate_alert from "./model/Auth/TokenExpirate";
import Vol from "./pages/admin/Vol";
import CircularIndeterminate from "./pages/page_tous/loading";
import Vols_client from "./pages/client/Vols_client";
import Erreur_404 from "./pages/error/404";
import Erreur_401 from "./pages/error/401";
import Pack_vols from "./pages/admin/Pack_vols";
import Reserve_pack_vols from "./pages/commerciel/Pack_vols_reserve";
import Home_commerceil from "./pages/commerciel/Home_commerceil";
import Home_comptable from "./pages/comptable/Home_comptable";
import Pack_Vols_comptable from "./pages/comptable/Pack_vols_comptabe";
import Pack_vols_paye from "./pages/comptable/pack_vols_paye";
import Clients from "./pages/commerciel/Clients";
import Pack from "./pages/admin/PackOmra";
import Reserve_pack_omra from "./pages/commerciel/OmraPack_reserve"; 
import Pack_Omra_comptable from "./pages/comptable/pack_omra_comptable";
import Pack_Omra_paye from "./pages/comptable/pack_omra_paye";
function App() {
  const { loading_auth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [expirate, setExpirate] = useState(false);
  const ck = Cookies.get("token");
  const isAuth = !!ck;
  const role = Cookies.get("role");
  const isAdmin = role == "admin";
  const isCommerciel = role == "commerciel";
  const isComptable = role == "comptable";

  useEffect(() => {
    if (ck !== undefined) {
      dispatch(Auth_user());
    }
  }, [ck]);

  const test_expirate = () => {
    const token = Cookies.get("token");
    if (token !== undefined) {
      const expirationDate = Cookies.get("token_expiration");
      const dt = new Date();
      const myDate = dt.toISOString();
      const date_new = new Date(myDate);
      const date_expirate = new Date(expirationDate);
      const timeDifferenceInMin = Math.abs((date_expirate - date_new) / 60000);
      if (4 > timeDifferenceInMin) {
        setExpirate(true);
      }
    }
  };

  if (loading_auth) {
    return (
      <>
        <CircularIndeterminate />
      </>
    );
  }

  return (
    <>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route
              path="/comptable"
              element={
                isAuth ? (
                  isComptable ? (
                    <Home_comptable />
                  ) : (
                    <Erreur_401 />
                  )
                ) : (
                  <Navigate to="/auth/login" state={{ form: "/comptable" }} />
                )
              }
            />
            <Route
              path="/comptable/pack_vols"
              element={
                isAuth ? (
                  isComptable ? (
                    <Pack_Vols_comptable />
                  ) : (
                    <Erreur_401 />
                  )
                ) : (
                  <Navigate to="/auth/login" state={{ form: "/comptable" }} />
                )
              }
            />
             <Route
              path="/comptable/pack_omra"
              element={
                isAuth ? (
                  isComptable ? (
                    <Pack_Omra_comptable />
                  ) : (
                    <Erreur_401 />
                  )
                ) : (
                  <Navigate to="/auth/login" state={{ form: "/comptable" }} />
                )
              }
            />
            <Route
              path="/comptable/pack_vols/paye/:id"
              element={
                isAuth ? (
                  isComptable ? (
                    <Pack_vols_paye />
                  ) : (
                    <Erreur_401 />
                  )
                ) : (
                  <Navigate to="/auth/login" state={{ form: "/comptable" }} />
                )
              }
            />
                      <Route
              path="/comptable/pack_omra/paye/:id"
              element={
                isAuth ? (
                  isComptable ? (
                    <Pack_Omra_paye/>
                  ) : (
                    <Erreur_401 />
                  )
                ) : (
                  <Navigate to="/auth/login" state={{ form: "/comptable" }} />
                )
              }
            />
            <Route
              path="/commerciel"
              element={
                isAuth ? (
                  isCommerciel ? (
                    <Home_commerceil />
                  ) : (
                    <Erreur_401 />
                  )
                ) : (
                  <Navigate to="/auth/login" state={{ form: "/commerciel" }} />
                )
              }
            />
            <Route
              path="/commerciel/pack_vols"
              element={
                isAuth ? (
                  isCommerciel ? (
                    <Reserve_pack_vols />
                  ) : (
                    <Erreur_401 />
                  )
                ) : (
                  <Navigate to="/auth/login" state={{ form: "/commerciel" }} />
                )
              }
            />
            <Route
              path="/commerciel"
              element={
                isAuth ? (
                  isCommerciel ? (
                    <Home_commerceil />
                  ) : (
                    <Erreur_401 />
                  )
                ) : (
                  <Navigate to="/auth/login" state={{ form: "/commerciel" }} />
                )
              }
            />
            <Route
              path="/commerciel/Clients"
              element={
                isAuth ? (
                  isCommerciel ? (
                    <Clients />
                  ) : (
                    <Erreur_401 />
                  )
                ) : (
                  <Navigate to="/auth/login" state={{ form: "/commerciel" }} />
                )
              }
            />
            <Route
              path="/commerciel/pack_omra"
              element={
                isAuth ? (
                  isCommerciel ? (
                  <Reserve_pack_omra/>
                  ) : (
                    <Erreur_401 />
                  )
                ) : (
                  <Navigate to="/auth/login" state={{ form: "/commerciel" }} />
                )
              }
            />

            <Route
              path="/admin"
              element={
                isAuth ? (
                  isAdmin ? (
                    <HomeAdmin />
                  ) : (
                    <Erreur_401 />
                  )
                ) : (
                  <Navigate to="/auth/login" state={{ form: "/admin" }} />
                )
              }
            />
            <Route
              path="admin/fournisseur"
              element={
                isAuth ? (
                  isAdmin ? (
                    <Fournisseur />
                  ) : (
                    <Erreur_401 />
                  )
                ) : (
                  <Navigate
                    to="/auth/login"
                    state={{ form: "/admin/fournisseur" }}
                  />
                )
              }
            />
            <Route
              path="admin/vol"
              element={
                isAuth ? (
                  isAdmin ? (
                    <Vol />
                  ) : (
                    <Erreur_401 />
                  )
                ) : (
                  <Navigate to="/auth/login" state={{ form: "/admin/vol" }} />
                )
              }
            />
            <Route
              path="admin/pack_vols"
              element={
                isAuth ? (
                  isAdmin ? (
                    <Pack_vols />
                  ) : (
                    <Erreur_401 />
                  )
                ) : (
                  <Navigate to="/auth/login" state={{ form: "/admin/vol" }} />
                )
              }
            />
            <Route
              path="admin/pack"
              element={
                isAuth ? (
                  isAdmin ? (
                    <Pack />
                  ) : (
                    <Erreur_401 />
                  )
                ) : (
                  <Navigate to="/auth/login" state={{ form: "/admin/vol" }} />
                )
              }
            />
            <Route
              path="/"
              element={
                isAuth ? (
                  isAdmin ? (
                    <HomeAdmin />
                  ) : isCommerciel ? (
                    <Home_commerceil />
                  ) : isComptable ? (
                    <Home_comptable />
                  ) : (
                    <Navigate to="/" />
                  )
                ) : (
                  // Redirect to login if user is not authenticated
                  <Navigate to="/auth/login" state={{ form: "/admin/vol" }} />
                )
              }
            />

            <Route path="auth/register" element={<Registre />} />
            <Route path="auth/valid" element={<Validation />} />
            <Route path="auth/login" element={<SignIn />} />

            <Route path="*" element={<Erreur_404 />} />
          </Routes>
        </BrowserRouter>
      </div>
      <Token_expirate_alert
        open={expirate}
        onConfirm={(e) => {
          dispatch(Auth_logout());
          dispatch(deconnection());
        }}
      />
    </>
  );
}

export default App;
