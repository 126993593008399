import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';

function ErreurDialoge({ open, onClose,content }) {
    const iconStyle = {
        color: 'red',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <div style={iconStyle}>
        <DisabledByDefaultOutlinedIcon sx={{fontSize: '10rem', }} />
        </div>
        </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  );
}

export default ErreurDialoge;