import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../api/Api_token";

export const get_clients= createAsyncThunk( "get clients" , async()=>{
      const response =  await instance.get('commerciel/Clients/clients');
      return response.data
})

const initialState = {
    loading: false,
    clients:[]
  };
  
  export const Client_slice = createSlice({
    name: "clients",
    initialState,
    reducers: {},
    extraReducers:(builder)=>{
         builder.addCase(get_clients.pending ,(state , action)=>{
            state.loading=true
         })
         builder.addCase(get_clients.fulfilled ,(state , action)=>{
            state.loading=false
            state.clients=action.payload
            console.log(action.payload)
         })
         builder.addCase(get_clients.rejected ,(state,action)=>{
            state.loading=false
         })
    }
  })

  export default Client_slice.reducer