import { configureStore ,MiddlewareArray} from '@reduxjs/toolkit'
import Auth from './Auth'
import fournisseur_slice from './admin/fournisseur_slice'
import  vol_slice  from './admin/vol_slice'
import Pack_Vols_slice from './admin/Pack_Vols_slice'
import  Pack_c_slice  from './admin/Pack_slice'
import Vols_client_slice from './client/Vols_client_slice'
import customMiddleware from './middlwer'
import  Reservation_slice  from './commerciel/reservation_slice'
import  Paye_slice  from './comptable/paye_slice'
import  Client_slice  from './tous/clients_slice'
import  Reservation_Omra  from './commerciel/reservation_omra _slice'
import paye_omra_slice from './comptable/paye_omra_slice'

export const Store=configureStore({
    reducer:{
      auth:Auth,
      fournisseur:fournisseur_slice,
      vol:vol_slice,
      pack:Pack_Vols_slice,
      pack_complete:Pack_c_slice,
      vols_clients:Vols_client_slice,
      reservation:Reservation_slice,
      paye:Paye_slice,
      clients:Client_slice,
      resrvation_omra:Reservation_Omra,
      payeOmra:paye_omra_slice
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(customMiddleware),
})