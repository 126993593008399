
import { Card } from "@mui/material";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { CreditCard } from "@mui/icons-material";




export default function Card4(){
    return(
        <Card sx={{background: 'rgb(2,0,36)', background: 'linear-gradient(135deg, rgba(2,0,36,1) 0%, rgba(24,111,101,1) 49%, rgba(181,203,153,1) 100%)' , color:"white" , padding:"1%"}}>
        <h3> <CreditCard />  test</h3>
        </Card>
    )
}