import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Box, Grid } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { Button } from "@mui/material";
import { filteretats } from "../../../data/vol_data";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Vols_client from "../../../pages/client/Vols_client";
import { Vols_clients } from "../../../store/client/Vols_client_slice";
import {
  Get_info_reservation_update,
  get_packs_name,
} from "../../../store/commerciel/reservation_slice";

export default function Reserevation_dialoge_update({
  open,
  onClose,
  onlick,
  id,
}) {
  const dispach = useDispatch();
  useEffect(() => {
    console.log(id);
    dispach(get_packs_name());
  }, []);

  return (
    <Box>
      <Dialog open={open} onClose={onClose} fullScreen>
        <DialogTitle sx={{ fontSize: "40px" }}>update reservation</DialogTitle>
        <Form_update send={onlick} onclose={onClose} id_res={id} />
      </Dialog>
    </Box>
  );
}

export function Form_update({ send, onclose, id_res }) {
  const filteretat = filteretats;
  const { update_info } = useSelector((state) => state.reservation);
  const { packs } = useSelector((state) => state.reservation);
  const [pack, setpack] = useState();
  const [erreurEmail, setErreurEmail] = useState();
  const [erreurEmail2, setErreurEmail2] = useState();
  const [ErreurTele, SetErreurTele] = useState();
  const [number, SetNumber] = useState();
  const [ErreurPlace, seterreurPlace] = useState();
  const [value_etat, setValue_etat] = useState();

  console.log(packs);
  useEffect(() => {
    setpack(update_info?.nom_packs_vols);
    console.log(update_info)
  }, [packs]);
  function isEmailValid(email) {
    // Utilisez une expression régulière pour valider l'e-mail
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  function inisal_erreur() {
    setErreurEmail();
    setErreurEmail2();
    SetErreurTele();
    seterreurPlace();
  }

  function inisal_state() {
    setpack();
    SetNumber();
    inisal_erreur();
  }
  function send_Data(e) {
    e.preventDefault();
    inisal_erreur();
    const form = e.target;
    const telephone = form.Telephone.value;
    const place = form.place.value;
    if (!isEmailValid(form.Email_client.value)) {
      setErreurEmail("Adresse e-mail invalide");
      return;
    }

    if (telephone.length < 10) {
      SetErreurTele("le numero telephone moin que 10");
      return;
    }
    if (place > number) {
      seterreurPlace("le nomber de place resreve plus que place disponible ");
      return;
    }

    const data = {
      nom_client: form.nom_client.value,
      prenom_client: form.prenom_client.value,
      email: form.Email_client.value,
      telephone: form.Telephone.value,
      remarque: form.remarque.value,
      pack: pack,
      place: form.place.value,
      id: id_res,
      societe:form.societe.value,
      adress:form.adress.value,
      date:form.date.value
    };
    console.log(data);
    send(data);
    inisal_state();
  }
  return (
    <Box
      component="form"
      onSubmit={send_Data}
      sx={{ width: "60%", marginLeft: "20%" }}
    >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <h2>info Client :</h2>
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Nom client"
            name="nom_client"
            autoComplete="Name Client"
            defaultValue={update_info.nom_client}
            autoFocus
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Prenom client"
            name="prenom_client"
            autoComplete="Prenom Client"
            defaultValue={update_info.prenom}
            autoFocus
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email"
            name="Email_client"
            autoComplete="Email Client"
            error={erreurEmail}
            helperText={erreurEmail ? erreurEmail : ""}
            autoFocus
            defaultValue={update_info.email}
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            type="number"
            label="Telephone Client"
            name="Telephone"
            autoComplete="Telephone Client"
            error={ErreurTele}
            helperText={ErreurTele ? ErreurTele : " "}
            autoFocus
            defaultValue={update_info.telephone}
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            label="Societe"
            autoFocus
            name="societe"
            defaultValue={update_info.societe}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            label="Adress"
            autoFocus
            name="adress"
            defaultValue={update_info.adress}
          />
        </Grid>
        <Grid item xs={12}>
          <h2>info Pack :</h2>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            sx={{ marginTop: "3%" }}
            id="pack"
            options={packs}
            getOptionLabel={(p) => `nom:${p.nom_packs_vols}`}
            openOnFocus
            // isOptionEqualToValue={(option, value) => option.nom === "test1"}
            value={{ nom_packs_vols: pack }}
            onChange={(event, selectedOption, reason) => {
              if (selectedOption) {
                setpack(selectedOption.nom_packs_vols);
                SetNumber(parseInt(selectedOption.place));
              }
              if (reason === "clear") {
                setpack("");
                SetNumber();
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="pack"
                variant="outlined"
                required
                id="pack"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            type="number"
            label="place"
            name="place"
            autoComplete="place"
            defaultValue={update_info?.place_reserve}
            error={ErreurPlace}
            helperText={ErreurPlace ? ErreurPlace : ""}
            autoFocus
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={{ marginTop: "3%" }}
            type="date"
            name="date"
            required
            fullWidth
            variant="outlined"
            label="Date d'expiration"
            defaultValue={update_info.date_expiration}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Remarque"
            name="remarque"
            defaultValue={update_info?.remarque}
            autoComplete="Remarque"
            autoFocus
          />
        </Grid>
      </Grid>
      <div style={{ position: "fixed", bottom: 30, right: 30 }}>
        <Button onClick={onclose}>Annuler</Button>

        <Button variant="contained" color="primary" type="submit">
          Update
        </Button>
      </div>
    </Box>
  );
}
