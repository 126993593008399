import {
  Autocomplete,
  Box,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  DialogActions,
  Button
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Get_Fournisseurs, Get_pack_vols } from "../../store/admin/Pack_slice";

export default function Ajouter_pack({ open, onclose , send }) {
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(Get_Fournisseurs())
    dispatch(Get_pack_vols())
  },[])
  return (
    <Box>
      <Dialog open={open} onClose={onclose} fullScreen>
        <DialogTitle sx={{ fontSize: "40px" }}>Ajouter pack</DialogTitle>
        <Page_ajouter onclose={onclose} send={send} />
      </Dialog>
    </Box>
  );
}

function Page_ajouter({onclose , send}) {
  const {pack_vols}=useSelector(state=>state.pack_complete)
  const {fournisseurs}=useSelector(state=>state.pack_complete)
  const [fournisseur_id , setFournisseur_id]=useState()
   

  function env(e){
    e.preventDefault();
    const form  = e.target
    const data ={
      pack_nom:form.name_pack.value,
      desc:form.desc.value,
      id_fournisseur:parseInt(fournisseur_id),
      vols:form.pack_vol.value,
      N_double:parseInt(form.N_double.value) ,
      P_double :parseFloat(form.P_double.value) ,
      N_TRP :parseInt(form.N_TRP.value),
      P_TRP: parseFloat(form.P_TRP.value),
      N_Quad:parseInt(form.N_Quad.value),
      P_Quad: parseFloat(form.P_Quad.value),
      N_Cinq:parseInt(form.N_Cinq.value),
      P_cinq:parseFloat(form.P_cinq.value)
    }
   setFournisseur_id()
   send(data)
  }

  return (
    <Box sx={{ marginLeft: "20%", marginRight: "20%" }} component="form" onSubmit={env}>
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12}>
          <TextField fullWidth placeholder="nom pack" name="name_pack" required />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            fullWidth
            sx={{ marginTop: "3%" }}
            id="fournisseur"
            options={fournisseurs}
            getOptionLabel={(p) => p.nom}
            openOnFocus
            isOptionEqualToValue={(option, value) => option.type === value.type}
            onChange={(event, selectedOption, reason) => {
              if (selectedOption) {
                setFournisseur_id(selectedOption.id)
              }
              if (reason === "clear") {
                fournisseur_id()
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="fournisseur"
                variant="outlined"
                required
                id="fournisseur"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            fullWidth
            sx={{ marginTop: "3%" }}
            id="pack vols"
            options={pack_vols}
            getOptionLabel={(p) => p.nom_packs_vols}
            openOnFocus
            isOptionEqualToValue={(option, value) => option.type === value.type}
            onChange={(event, selectedOption, reason) => {
              if (selectedOption) {

              }
              if (reason === "clear") {
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="pack vols"
                name="pack_vol"
                variant="outlined"
                required
                id="pack vols"
                fullWidth
              />
            )}
          />
        </Grid>
        <>
          <Grid item xs={2}>
            <Typography variant="h5" marginTop="3px">
              Double :
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <TextField fullWidth type="number" placeholder="Number place double" name="N_double" required />
          </Grid>
          <Grid item xs={5}>
            <TextField fullWidth type="number" placeholder="Prix place" name="P_double" required />
          </Grid>
        </>
        <>
          <Grid item xs={2}>
            <Typography variant="h5" marginTop="3px">
              TRP :
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <TextField fullWidth type="number" placeholder="Number place TRP" name="N_TRP"  required />
          </Grid>
          <Grid item xs={5}>
            <TextField fullWidth  type="number" placeholder="Prix place" name="P_TRP" required/>
          </Grid>
        </>
        <>
          <Grid item xs={2}>
            <Typography variant="h5" marginTop="3px">
              QUAD
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <TextField fullWidth type="number" placeholder="Number place Quad" name="N_Quad" required />
          </Grid>
          <Grid item xs={5}>
            <TextField fullWidth type="number" placeholder="Prix place"  name="P_Quad" required  />
          </Grid>
        </>
        <>
          <Grid item xs={2}>
            <Typography variant="h5" marginTop="3px">
              CINQ
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <TextField fullWidth type="number" placeholder="Number place Cinq" name="N_Cinq" required />
          </Grid>
          <Grid item xs={5}>
            <TextField fullWidth type="number" placeholder="Prix place" name="P_cinq" required />
          </Grid>
          <Grid item xs={12}>
          <TextField fullWidth placeholder="desc" name="desc" required />
        </Grid>
        </>
      </Grid>
      <div style={{ position: "fixed", bottom: 30, right: 30 }}>
        <Button onClick={onclose}>Annuler</Button>

        <Button variant="contained" color="primary" type="submit">
          ajouter
        </Button>
      </div>
    </Box>
  );
}
