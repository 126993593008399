import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './en/translation.json'
import frTranslation from "./fr/translation.json"
import arTranslation from './ar/translation.json'

i18n
.use(initReactI18next)
.init({
    resources: {
      en: {
        translation: enTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      ar:{
        translation:arTranslation,
      }
    },
    lng: 'fr', // Default language
    fallbackLng: 'en'
})

export default i18n