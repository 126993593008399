import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import localImage from "../../data/image/logo_nusuk.jpg";
import { Table, TableBody, TableRow } from "@mui/material";

// Define your styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,

  },
  header: {
    fontSize: 26,
    fontWeight: "bold",
    marginBottom: 30,
    textAlign: "center",
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
  },
  label: {
    fontSize: 16,
  },
  section:{
    marginBottom:15,
    marginLeft:150,
  },
  value:{
    fontSize:23,
    marginBottom:25,
  },
  signature:{
     textAlign :"right",
    marginTop:10
  }

});


const FacturePackVols = () => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Text style={styles.header}>BON DE REÇU N 007</Text>
      <Text style={styles.value}>Information societe :</Text>
      <View style={styles.section}>
        <Text>Phone :0603250453</Text>
      </View>
      <View style={styles.section}>
        <Text>Caisse : Rabat</Text>
      </View>
      <Text style={styles.value}>Information client :</Text>
      <View style={styles.section}>
        <Text>Nom : Anass angra</Text>
      </View>
      <View style={styles.section}>
        <Text>Phone : 0603250453</Text>
      </View>
      <Text style={styles.value}>Information commande :</Text>
      <View style={styles.section}>
        <Text>pack : pack_test</Text>
      </View>
      <View style={styles.section}>
        <Text>Place reserve : 2</Text>
      </View>
      <View style={styles.section}>
        <Text>Montant total : 2000 dh</Text>
      </View>
      <Text style={styles.value}>Information commande :</Text>
      <View style={styles.section}>
        <Text>Type : cash</Text>
      </View>
      <View style={styles.section}>
        <Text> montant paye : 1000 dh</Text>
      </View> 
      <View style={styles.section}>
        <Text>Montant reste : 1000 dh</Text>
      </View>
      <View style={styles.signature}>
        <Text>Signature</Text>
      </View>

    </Page>
  </Document>
);

export default FacturePackVols;
