import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Box, FormControlLabel, Grid } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { Button } from "@mui/material";
import { filteretats } from "../../../data/vol_data";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Vols_client from "../../../pages/client/Vols_client";
import { Vols_clients } from "../../../store/client/Vols_client_slice";
import { get_packs_name } from "../../../store/commerciel/reservation_slice";
import Checkbox from "@mui/material/Checkbox";

export default function Reserevation_dialoge({ open, onClose, onlick }) {
  const dispach = useDispatch();
  useEffect(() => {
    dispach(get_packs_name());
  }, []);

  return (
    <Box>
      <Dialog open={open} onClose={onClose} fullScreen>
        <DialogTitle sx={{ fontSize: "36px" }}>Ajouter reservation</DialogTitle>
        <Form_reservation send={onlick} onclose={onClose} />
      </Dialog>
    </Box>
  );
}

export function Form_reservation({ send, onclose }) {
  const filteretat = filteretats;
  const { packs } = useSelector((state) => state.reservation);
  const [etat, setEtat] = useState();
  const [pack, setpack] = useState();
  const [erreurEmail, setErreurEmail] = useState();
  const [TG, setTG] = useState(false);
  const [enf, setEnf] = useState(false);
  const [penalite, setPenalite] = useState(false);

  const [ErreurTele, SetErreurTele] = useState();
  const [number, SetNumber] = useState();
  const [ErreurPlace, seterreurPlace] = useState();
  console.log(packs);
  function isEmailValid(email) {
    // Utilisez une expression régulière pour valider l'e-mail
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  function inisal_erreur() {
    setErreurEmail();
    SetErreurTele();
    seterreurPlace();
  }

  function inisal_state() {
    setEtat();
    setpack();
    SetNumber();
    inisal_erreur();
  }
  function send_Data(e) {
    e.preventDefault();
    inisal_erreur();
    console.log(number);
    console.log(typeof number);
    const data = e.target;
    const telephone = data.Telephone.value;
    const place = data.place.value;
    if (!isEmailValid(data.Email_client.value)) {
      setErreurEmail("Adresse e-mail invalide");
      return;
    }
    if (telephone.length < 10) {
      SetErreurTele("le numero telephone moin que 10");
      return;
    }
    if (place > number) {
      seterreurPlace("le nomber de place resreve plus que place disponible ");
      return;
    }
    const formData = new FormData();

    formData.append("nom_client", data.nom_client.value);
    formData.append("prenom_client", data.prenom_client.value);
    formData.append("email", data.Email_client.value);
    formData.append("telephone", telephone);
    formData.append("remarque", data.remarque.value);
    formData.append("pack", pack);
    formData.append("place", data.place.value);
    formData.append("ice", data.ice.value);
    formData.append("societe", data.societe.value);
    formData.append("adress", data.adress.value);
    formData.append("date", data.date.value);
    if (TG) {
      formData.append("tg", TG)
      formData.append("number_tg", data.n_t_g.value);
      formData.append("prix_tg", data.p_t_g.value);
    }

    if (enf) {
      formData.append("enf" , enf)
      formData.append("number_enf", data.n_t_e.value);
      formData.append("prix_enf", data.p_t_e.value);
    }
    if (penalite) {
      formData.append("penalite" , penalite)
      formData.append("number_p", data.n_t_p.value);
      formData.append("prix_p", data.p_t_p.value);
    }
  send(formData);
  inisal_state();
  }
  return (
    <Box
      component="form"
      onSubmit={send_Data}
      sx={{ width: "60%", marginLeft: "20%" }}
    >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <h2>info Client :</h2>
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Nom client"
            name="nom_client"
            autoComplete="Name Client"
            autoFocus
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Prenom client"
            name="prenom_client"
            autoComplete="Prenom Client"
            autoFocus
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email"
            name="Email_client"
            autoComplete="Email Client"
            error={erreurEmail? true : false}
            helperText={erreurEmail ? `${erreurEmail}` : ""}
            autoFocus
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            type="number"
            label="Telephone Client"
            name="Telephone"
            autoComplete="Telephone Client"
           error={ErreurTele ? true : false}
            helperText={ErreurTele ? `${ErreurTele}` : " "}
            autoFocus
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField required fullWidth label="Ice" autoFocus name="ice" />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            label="Societe"
            autoFocus
            name="societe"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Adress"
            autoFocus
            name="adress"
          />
        </Grid>
        <Grid item xs={12}>
          <h2>info Pack :</h2>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            sx={{ marginTop: "3%" }}
            id="pack"
            options={packs}
            getOptionLabel={(p) => `nom:${p.nom_packs_vols}-place: ${p.place}`}
            openOnFocus
            isOptionEqualToValue={(option, value) => option.type === value.type}
            onChange={(event, selectedOption, reason) => {
              if (selectedOption) {
                setpack(selectedOption.nom_packs_vols);
                SetNumber(parseInt(selectedOption.place));
              }
              if (reason === "clear") {
                setpack("");
                SetNumber();
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="pack"
                variant="outlined"
                required
                id="pack"
              />
            )}
          />
        </Grid>
        {/*  <Grid item xs={6}>
        <Autocomplete
            sx={{ marginTop: "3%" }}
            id="etat"
            options={filteretat}
            getOptionLabel={(e) => e.nom}
            openOnFocus
            isOptionEqualToValue={(option, value) => option.nom === value.nom}
            onChange={(event, selectedOption, reason) => {
              if (selectedOption) {
                setEtat(selectedOption.nom)
              } 
              if (reason === "clear") {
                  setEtat('')
              } 
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="état pack"
                variant="outlined"
                required
                id="etat"
              />
            )}
          />
          

            </Grid>*/}
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            type="number"
            label="place"
            name="place"
            autoComplete="place"
            error={ErreurPlace ? true : false}
            helperText={ErreurPlace ? ` ${ErreurPlace}` : ""}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={{ marginTop: "3%" }}
            type="date"
            name="date"
            required
            fullWidth
            variant="outlined"
            label="Date d'expiration"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Remarque"
            name="remarque"
            autoComplete="Remarque"
          />
        </Grid>
        <Grid item xs={12}>
          <h2>Option :</h2>
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={TG}
                onChange={(e) => {
                  setTG(e.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={<h3>ticket gratuit</h3>}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required={TG}
            fullWidth
            type="number"
            label="Number ticket gratuit "
            name="n_t_g"
            autoComplete="Number ticket gratuit "
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required={TG ? true : false}
            fullWidth
            type="number"
            label="prix ticket gratuit "
            name="p_t_g"
            autoComplete="prix ticket gratuit "
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={enf}
                onChange={(e) => {
                  setEnf(e.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={<h3>Enfants -2 ans</h3>}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required={enf ? true : false}
            fullWidth
            type="number"
            label=" NumberEnfants -2 ans "
            name="n_t_e"
            autoComplete="Enfants -2 ans "
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required={enf ? true : false}
            fullWidth
            type="number"
            label="Prix Enfants -2 ans "
            name="p_t_e"
            autoComplete="Enfants -2 ans"
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={penalite}
                onChange={(e) => {
                  setPenalite(e.target.checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={<h3>Pénalité</h3>}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required={penalite ? true : false}
            fullWidth
            type="number"
            label="Number Pénalité "
            name="n_t_p"
            autoComplete="Pénalité "
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required={penalite ? true : false}
            fullWidth
            type="number"
            label=" Prix Pénalité "
            name="p_t_p"
            autoComplete="Prix Pénalité"
          />
        </Grid>
      </Grid>
      <div style={{ position: "fixed", bottom: 30, right: 30 }}>
        <Button onClick={onclose}>Annuler</Button>

        <Button variant="contained" color="primary" type="submit">
          ajouter
        </Button>
      </div>
    </Box>
  );
}
