import { Card, Paper } from "@mui/material";

export default function ListPack() {
  return (
    <Card>
      <Paper>
        <h4>test</h4>
      </Paper>
    </Card>
  );
}
