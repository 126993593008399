import Navbar from "../../components/Appbar";
import {
  Box,
  Grid,
} from "@mui/material";
import MiniDrawer from "../../components/draw";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchPdf,
  get_caisse,
  get_type_payment,
  paye_reservation,
} from "../../store/comptable/paye_slice";
import BackLoading from "../page_tous/back_loading";
import { useParams } from "react-router-dom";
import SuccesDialoge from "../../model/succes";
import ErreurDialoge from "../../model/Erreur";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Info_personnel  , Info_reservation , Operation_payment } from "./pack_vols_paye";
import { PdfPackOmra, getInforeservation, payePackOmara } from "../../store/comptable/paye_omra_slice";



export default function Pack_Omra_paye() {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    get_data();
  }, []);
  function get_data() {
    dispatch(getInforeservation(id));
  }
 
  const { loading } = useSelector((state) => state.payeOmra);
  return (
    <div style={{ background: "#EEEEEE", minHeight: "100vh" }}>
      <Navbar />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <MiniDrawer />
        <Box
          component="main"
          sx={{
            marginTop: "7%",
            width: "100%",
          }}
        >
          <Box
            component="main"
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "3%",
              marginLeft: "3%",
            }}
          >
            <Page_payment id={id} dt={get_data} />
          </Box>
        </Box>
      </Box>
      <BackLoading open={loading} />
    </div>
  );
}

export function Page_payment({ id, dt }) {
    const  info_reservation = useSelector((state) => state.payeOmra.Inforesrvation);
  const dispatch = useDispatch();
  const [succes, Setsucces] = useState(false);
  const [error, setError] = useState(false);

  function payment(data) {
    data.append("id", id);
    dispatch(payePackOmara(data)).then((result) => {
      if (payePackOmara.fulfilled.match(result)) {
        Setsucces(true);
        dispatch(PdfPackOmra(id))
      }
      if (payePackOmara.rejected.match(result)) {
        setError(true);
      }
    });
  }
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={8} >
          <Grid container rowSpacing={3}>
            <Grid xs={12} item>
              <Info_reservation data={info_reservation} />
            </Grid>
            <Grid item xs={12}>
              <Operation_payment func={payment} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Info_personnel data={info_reservation} />
        </Grid>
      </Grid>
      <SuccesDialoge
        open={succes}
        onClose={() => {
          Setsucces(false);
          dt();
        }}
        content={<h3>paiement effectué</h3>}
      />
      <ErreurDialoge
        open={error}
        onClose={() => {
          setError(false);
        }}
        content="paiement  non effectué"
      />
    </>
  );
}

