import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../api/Api_token";
import { create } from "@mui/material/styles/createTransitions";
import { Update } from "@mui/icons-material";
import { buildTimeValue } from "@testing-library/user-event/dist/utils";

export const Get_pack_vols = createAsyncThunk("get pack ", async () => {
  const response = await instance.get("pack_vols");
  return response.data;
});

export const Get_Fournisseurs = createAsyncThunk(
  "get fournisseurs ",
  async () => {
    const response = await instance.get("fournisseur_name");
    return response.data;
  }
);

export const Send_new_pack = createAsyncThunk(
  " ajouter new pack",
  async (data) => {
    const response = await instance.post("admin/pack_c/new_pack", data);
    return response.data;
  }
);

export const Get_packs = createAsyncThunk("get packs ", async (data) => {
  const response = await instance.get(
    `admin/pack_c/get_packs/${data.number}?page=${data.page + 1}`
  );
  return response.data;
});

export const Delete_pack = createAsyncThunk("delete pack", async (id) => {
  const response = await instance.delete(`admin/pack_c/delete_pack/${id}`);
  return response.data;
});

export const Get_pack = createAsyncThunk("get pack", async (id) => {
  const response = await instance.get(`admin/pack_c/get_info/${id}`);
  return response.data;
});

export const update_pack = createAsyncThunk("send update", async (data) => {
  const response = await instance.post(`admin/pack_c/update_pack`, data);
  return response;
});

const initialState = {
  loading: false,
  pack_vols: [],
  fournisseurs: [],
  packs: [],
  pack: {},
};

export const Pack_c_slice = createSlice({
  name: "pack",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Get_pack_vols.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Get_pack_vols.fulfilled, (state, action) => {
      state.loading = false;
      state.pack_vols = action.payload;
    });
    builder.addCase(Get_pack_vols.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(Get_Fournisseurs.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Get_Fournisseurs.fulfilled, (state, action) => {
      state.loading = false;
      state.fournisseurs = action.payload;
    });
    builder.addCase(Get_Fournisseurs.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(Send_new_pack.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Send_new_pack.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Send_new_pack.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(Get_packs.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Get_packs.fulfilled, (state, action) => {
      state.loading = false;
      state.packs = action.payload;
    });
    builder.addCase(Get_packs.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(Delete_pack.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Delete_pack.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Delete_pack.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Get_pack.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Get_pack.fulfilled, (state, action) => {
      state.loading = false;
      state.pack = action.payload;
      
    });
    builder.addCase(Get_pack.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(update_pack.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(update_pack.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(update_pack.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default Pack_c_slice.reducer;
