import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

function SuccesDialoge({ open, onClose,content }) {
    const iconStyle = {
        color: 'green',
        fontSize: '4rem', // Ajustez la taille de l'icône ici
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <div style={iconStyle}>
        <CheckCircleOutlineIcon sx={{fontSize: '10rem', }} />
        </div>
        </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  );
}

export default SuccesDialoge;