import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../api/Api_token";

export const get_pack = createAsyncThunk("get pack", async (data) => {
  const response = await instance.get(
    `admin/pack/getpack/${data.num}?page=${data.page + 1}`
  );
  return response.data;
});

export const delete_pack = createAsyncThunk("delete pack", async (id) => {
  const response = await instance.delete(`admin/pack/delete_pack/${id}`);
  return response;
});

export const Vols_pack = createAsyncThunk("vols pack", async (data) => {
  const response = await instance.get(
    `admin/pack/vols?type=${data?.type}&origine=${data?.origine}&destination=${data?.destination}&date=${data?.date}&datea=${data?.datea}`
  );
  return response.data;
});

export const New_pack = createAsyncThunk("new pack", async (data) => {
  const response = await instance.post(`admin/pack/new_pack`, data);
  return response.data;
});

export const gettag = createAsyncThunk("get tag", async () => {
  const response = await instance.get("tag/tags/pack/pack_vol");
  return response.data;
});

const initialState = {
  loading: false,
  pack: [],
  tags: [],
};

export const Pack_slice = createSlice({
  name: "pack",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(get_pack.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_pack.fulfilled, (state, action) => {
      state.loading = false;
      state.pack = action.payload;
    });
    builder.addCase(get_pack.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(delete_pack.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(delete_pack.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(delete_pack.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(Vols_pack.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Vols_pack.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Vols_pack.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(New_pack.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(New_pack.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(New_pack.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(gettag.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(gettag.fulfilled, (state, action) => {
      state.loading = false;
      state.tags = action.payload;
    });
    builder.addCase(gettag.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default Pack_slice.reducer;
