import Navbar from "../../components/Appbar";
import MiniDrawer from "../../components/draw";
import { Autocomplete, Box, Card, Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { Typography } from "@mui/material";
import { TableContainer } from "@mui/material";
import { Paper } from "@mui/material";
import { TextField } from "@mui/material";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { TablePagination } from "@mui/material";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { get_clients } from "../../store/tous/clients_slice";


export default function Client() {
  // paginate

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch()

  const {clients}=useSelector(state=>state.clients)
  useEffect(()=>{
      get_data()
  },[])



  function get_data(){
       dispatch(get_clients())
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <div style={{ background: "#EEEEEE", minHeight: "100vh" }}>
      <Navbar />
      <Box sx={{ display: "flex" }}>
        <MiniDrawer />
        <Box
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "90px",
            width: "100%",
          }}
        >
          <Box
            component="main"
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "5%",
              marginLeft: "5%",
            }}
          >
            <Card>
              <Paper>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" colSpan={2}>
                          <Typography variant="h4">
                            <b>Clients</b>
                          </Typography>
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                          <TextField label="ice" variant="standard" id="ice" />
                        </TableCell>
                        <TableCell align="center" colSpan={2} >
                          <TextField label="societe" variant="standard" id="societe" />
                        </TableCell>
                        <TableCell align="center">
                          <Button variant="contained">+add</Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                      <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>Ice</b>
                        </TableCell>
                      <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>societe</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>Adress</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>nom</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>prenom</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>Telephone</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                         <b>Action</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { clients
                        ? clients?.data?.map((c) => (
                            <TableRow
                              key={c.id}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                            >
                              <TableCell align="center">
                                {c?.ice}
                              </TableCell>
                              <TableCell align="center">{c?.societe}</TableCell>
                              <TableCell align="center">
                                {c?.adress}
                              </TableCell>
                              <TableCell align="center">{c?.prenom}</TableCell>
                              <TableCell align="center">{c?.prenom}</TableCell>
                              <TableCell align="center">{c?.telephone}</TableCell>
                              <TableCell align="center">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                 
                                  <IconButton
                                    aria-label="update"
                                    color="primary"
                                    size="small"
                                  //  onClick={()=>{
                                    //  info_update(c.id)
                                    //}}
                                  >
                                    <ModeEditOutlineIcon />
                                  </IconButton>
                                 
                                   <IconButton
                                    aria-label="delete"
                                    size="small"
                                    color="error"
                                    //onClick={()=>{setDelete_dialog(true) ; setId_delete(r.id)}}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                 
                                </div>
                              </TableCell>
                            </TableRow>
                          ))
                        : Array.from({ length: 6 }, (_, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <TablePagination
                rowsPerPageOptions={[5, 25, 100]}
                component="div"
                count={1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
