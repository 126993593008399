import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {instance} from "../../api/Api_token";



export const get_fournisseurs = createAsyncThunk("fournisseurs", async (data) => {


  const response = await instance.get(`admin/fournisseur/fournisseurs/${data?.num}?page=${data?.page+1}&pays=${data?.pays}&ville=${data?.ville}&type=${data?.type}&order=${data?.order}`,data ,{
  });
  return response.data;
});


export const delete_fournisseur = createAsyncThunk("supprimer fournisseur" , async(id)=>{
        const response = await instance.delete(`admin/fournisseur/delete_fournisseurs/${id}`)
        return response.data
})

export const new_fournisseur = createAsyncThunk("ajouter fournisseur" , async(data)=>{
    const response = await instance.post('admin/fournisseur/new_fournisseur' , data)
    return response.data
})

export const up_fournisseur = createAsyncThunk("update fournisseur" , async(data )=>{
   
  const response = await instance.post(`admin/fournisseur/update_fournisseurs/${data.get("id")}`, data)
  return response.data
})

export const get_fournisseur = createAsyncThunk("get fournisseur " , async(id)=>{
  const response = await instance.get(`admin/fournisseur/fournisseur/${id}`)
  return response.data
})

export const get_type = createAsyncThunk("get type" , async()=>{
  const response = await instance.get(`admin/fournisseur/get_type`)
  return response.data
})

export const get_pays= createAsyncThunk("get pays " , async()=>{
    const response = await instance.get("admin/fournisseur/get_pays")
    return response.data
})

export const get_ville= createAsyncThunk("get ville " , async(pays)=>{
  const response = await instance.get(`admin/fournisseur/get_ville/${pays}`)
  return response.data
})


const initialState = {
  fournisseurs: {},
  fournisseur:{},
  loading_fournisseur: false,
  loading_fournisseur_back:false,
  erreur_code: null,
  type:[],
  pays:[],
  ville:[]
};

export const fournisseur_slice = createSlice({
  name: "fournisseur",
  initialState,
  reducers: {},
  extraReducers: (builder) => {


    builder.addCase(get_fournisseurs.pending, (state, action) => {
      state.loading_fournisseur_back  = true;
    });
    builder.addCase(get_fournisseurs.fulfilled, (state, action) => {
      state.loading_fournisseur_back  = false;
      state.fournisseurs=action.payload
    });
    builder.addCase(get_fournisseurs.rejected, (state, action) => {
       state.loading_fournisseur_back  = false;
     
     
    });

    /////////////////////

    builder.addCase(delete_fournisseur.pending , (state , action)=>{
      state.loading_fournisseur_back = true
    })
    builder.addCase(delete_fournisseur.fulfilled , (state, action)=>{
      state.loading_fournisseur_back = false
    })
    builder.addCase(delete_fournisseur.rejected , (state, action)=>{
      state.loading_fournisseur_back = false
    })
 
     //////////////

    builder.addCase(new_fournisseur.pending , (state , action )=>{
        state.loading_fournisseur_back = true
    })
    builder.addCase(new_fournisseur.fulfilled , (state , action)=>{
        state.loading_fournisseur_back = false
        console.log(action.payload)
    })
    builder.addCase(new_fournisseur.rejected ,(state, action)=>{
      state.loading_fournisseur_back = false
      console.log(action.error.message)
    })

    /////////////////

    builder.addCase(up_fournisseur.pending , (state , action)=>{
        state.loading_fournisseur_back = true 
    })
    builder.addCase(up_fournisseur.fulfilled , (state , action)=>{
      state.loading_fournisseur_back=false
    })
    builder.addCase(up_fournisseur.rejected , (state , action)=>{
       state.loading_fournisseur_back= false
    })

    builder.addCase(get_fournisseur.pending , (state , action)=>{
      state.loading_fournisseur_back = true 
  })
  builder.addCase(get_fournisseur.fulfilled , (state , action)=>{
    state.loading_fournisseur_back=false
    state.fournisseur= action.payload.data
  })
  builder.addCase(get_fournisseur.rejected , (state , action)=>{
     state.loading_fournisseur_back= false
  })

  //////////

  builder.addCase(get_type.pending , (state , action)=>{
      state.loading_fournisseur_back=true
  })
  builder.addCase(get_type.fulfilled , (state , action)=>{
    state.loading_fournisseur_back=false
    state.type=action.payload
  })
  builder.addCase(get_type.rejected , (state , action)=>{
    state.loading_fournisseur_back=false
  })

  
  builder.addCase(get_pays.pending , (state , action)=>{
    state.loading_fournisseur_back=true
})
builder.addCase(get_pays.fulfilled , (state , action)=>{
  state.loading_fournisseur_back=false
  state.pays=action.payload
})
builder.addCase(get_pays.rejected , (state , action)=>{
  state.loading_fournisseur_back=false
})

builder.addCase(get_ville.pending , (state , action)=>{
  state.loading_fournisseur_back=true
})
builder.addCase(get_ville.fulfilled , (state , action)=>{
state.loading_fournisseur_back=false
state.ville=action.payload

})
builder.addCase(get_ville.rejected , (state , action)=>{
state.loading_fournisseur_back=false
})




    

  },
});

export default fournisseur_slice.reducer;
