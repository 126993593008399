import { Dialog, DialogTitle, DialogActions, Grid } from "@mui/material";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

export default function Info_reservation({open  , onclose}) {
    const {info}=useSelector(state=>state.reservation)
  return (
    <Dialog open={open} onClose={onclose}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{padding:"10%" , paddingTop:'0%'}}>
        <Grid item xs={12}><h1>Info Reservation</h1></Grid>
        <Grid item xs={12}>
          <h3>info client</h3>
        </Grid>
        <Grid item xs={12}>
          <p><b>name :</b> {info?.nom_client} {info?.prenom}</p>
        </Grid>
        <Grid item xs={12}>
          <p><b>Email :</b> {info?.email} </p>
        </Grid>
        <Grid item xs={12}>
          <p><b>Telephone :</b> {info?.telephone}</p>
        </Grid>
        <Grid item xs={12}>
          <h3>info pack</h3>
        </Grid>
        <Grid item xs={6}><b>Pack :</b> {info?.nom_packs_vols}</Grid>
        <Grid item xs={6}><b>Type :</b> {info?.type}</Grid>
        <Grid item xs={6}><b>Place reserve :</b> {info?.place_reserve}</Grid>
        <Grid item xs={6}><b>Etat :</b>{info?.etat}</Grid>
        <Grid item xs={12}><b>remarque :</b> {info?.remarque}</Grid>
      </Grid>
      <DialogActions>
        <Button onClick={onclose}>Annuler</Button>
      </DialogActions>
    </Dialog>
  );
}
