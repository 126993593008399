
    import React from 'react';
    import Backdrop from '@mui/material/Backdrop';
    import CircularProgress from '@mui/material/CircularProgress';
    
 export default  function BackLoading({ open}) {
      return (
        <Backdrop
          sx={{   display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999,  }}
          open={open}
        >
          <CircularProgress  />
        </Backdrop>
      );
    }
    
    
    /* 
    
    color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 
    
    
    
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 9999, 
    }}>*/
    
    
    
    
    
    
