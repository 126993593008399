import { useEffect, useState } from "react";
import Navbar from "../../components/Appbar";
import MiniDrawer from "../../components/draw";
import {
  Box,
  Card,
  Grid,
  IconButton,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Ajouter_pack from "../../model/pack/ajouter_pack";
import BackLoading from "../page_tous/back_loading";
import {
  Delete_pack,
  Get_Fournisseurs,
  Get_pack,
  Get_pack_vols,
  Get_packs,
  Send_new_pack,
  update_pack,
} from "../../store/admin/Pack_slice";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableContainer } from "@mui/material";
import { TextField } from "@mui/material";
import SuccesDialoge from "../../model/succes";
import ErreurDialoge from "../../model/Erreur";
import { TablePagination } from "@mui/material";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import { Skeleton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteConfirmationDialog from "../../model/DeleteConfirme";
import { Send } from "@mui/icons-material";
import Update_pack from "../../model/pack/update_pack";

export default function Pack() {
  return (
    <div style={{ background: "#EEEEEE", minHeight: "100vh" }}>
      <Navbar />
      <Box sx={{ display: "flex" }}>
        <MiniDrawer />
        <Box
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "90px",
            width: "100%",
          }}
        >
          <Box
            component="main"
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "3%",
              marginLeft: "3%",
            }}
          >
            <Page_pack />
          </Box>
        </Box>
      </Box>
    </div>
  );
}

function Page_pack() {
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.pack_complete);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const { packs } = useSelector((state) => state.pack_complete);

  const [AddPack, SetAddPack] = useState(false);
  const [succesAdd, setSuccesAdd] = useState(false);
  const [errorAdd, SetErrorAdd] = useState(false);

  const [deletePack, setDeletePack] = useState(false);
  const [delete_id, setdelete_id] = useState();
  const [succesDelete, setSuccesDelete] = useState(false);
  const [errorDelete, SetErrorDelete] = useState(false);

  const [Pack_update, setPack_update] = useState(false);
  const [update_id, setUpdate_id] = useState();
  const [UpdateSucces, setUpdateSucces] = useState(false);
  const [UpdateError, setUpdateError] = useState(false);
  const [Data_update, setData_update] = useState();

  useEffect(() => {
    get_packs_();
  }, [rowsPerPage, page]);

  function get_packs_() {
    const data = {
      number: rowsPerPage,
      page: page,
    };
    dispatch(Get_packs(data));
  }

  function add_pack(data) {
    SetAddPack(false);
    dispatch(Send_new_pack(data)).then((result) => {
      if (Send_new_pack.fulfilled.match(result)) {
        setSuccesAdd(true);
      }
      if (Send_new_pack.rejected.match(result)) {
        SetErrorAdd(true);
      }
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function supp_Pack() {
    setDeletePack(false);
    dispatch(Delete_pack(delete_id)).then((result) => {
      if (Delete_pack.fulfilled.match(result)) {
        setSuccesDelete(true);
      }
      if (Delete_pack.rejected.match(result)) {
        SetErrorDelete(true);
      }
      setdelete_id();
    });
  }

  function get_data_update(id) {
    dispatch(Get_pack(id)).then((result) => {
      if (Get_pack.fulfilled.match(result)) {
        setPack_update(true);
      }
      if (Get_pack.rejected.match(result)) {
        setUpdateError(true);
      }
    });
  }

  function send_update(data) {
    data.id = update_id;
    setPack_update(false)
    dispatch(update_pack(data)).then((result) => {
      if (update_pack.fulfilled.match(result)) {
        setUpdateSucces(true);
      }
      if (update_pack.rejected.match(result)) {
        setUpdateError(true);
      }
    });
  }

  return (
    <>
      <Card>
        <Paper>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography variant="h4">
                      <b>Pack </b>
                    </Typography>
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => {
                        SetAddPack(true);
                      }}
                    >
                      +add
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" sx={{ width: "18%", top: 65 }}>
                    <b>Name</b>
                  </TableCell>

                  <TableCell align="center" sx={{ width: "18%", top: 65 }}>
                    <b>Origine</b>
                  </TableCell>
                  <TableCell align="center" sx={{ width: "18%", top: 65 }}>
                    <b>destination</b>
                  </TableCell>
                  <TableCell align="center" sx={{ width: "18%", top: 65 }}>
                    <b>date depart</b>
                  </TableCell>
                  <TableCell align="center" sx={{ width: "18%", top: 65 }}>
                    <b>date retour</b>
                  </TableCell>
                  <TableCell align="center" sx={{ width: "18%", top: 65 }}>
                    <b>pack vols</b>
                  </TableCell>

                  <TableCell align="center" sx={{ width: "18%", top: 65 }}>
                    <b>action</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {packs
                  ? packs?.data?.map((p) => (
                      <TableRow hover role="checkbox" tabIndex={-1} key={p?.id}>
                        <TableCell align="center">{p?.nom_pack_omra}</TableCell>
                        <TableCell align="center">{p?.airport_aller}</TableCell>
                        <TableCell align="center">
                          {p?.airport_destination}
                        </TableCell>
                        <TableCell align="center">{p?.date_aller}</TableCell>
                        <TableCell align="center">{p?.date_retour}</TableCell>
                        <TableCell align="center">
                          {p?.nom_packs_vols}
                        </TableCell>
                        <TableCell align="center">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <IconButton
                              aria-label="info"
                              color="secondary"
                              size="small"
                            >
                              <InfoIcon />
                            </IconButton>
                            <IconButton
                              aria-label="update"
                              color="primary"
                              size="small"
                              onClick={() => {
                                setUpdate_id(p.id);
                                get_data_update(p.id);
                              }}
                            >
                              <ModeEditOutlineIcon />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              size="small"
                              color="error"
                              onClick={() => {
                                setdelete_id(p.id);
                                setDeletePack(true);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  : Array.from({ length: 6 }, (_, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </TableCell>
                        <TableCell align="center">
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <TablePagination
          rowsPerPageOptions={[5, 25, 100]}
          component="div"
          count={packs?.total ?? 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <Ajouter_pack
        open={AddPack}
        onclose={() => {
          SetAddPack(false);
        }}
        send={add_pack}
      />
      <SuccesDialoge
        open={succesAdd}
        onClose={() => {
          setSuccesAdd(false);
          get_packs_();
        }}
        content="pack vol ajouter avec succes"
      />
      <ErreurDialoge
        open={errorAdd}
        onClose={() => {
          SetErrorAdd(false);
          get_packs_();
        }}
        content="Échec de l'ajouter du pack "
      />
      <DeleteConfirmationDialog
        open={deletePack}
        content="Êtes-vous sûr de vouloir supprimer ce pack ?"
        onClose={() => {
          setDeletePack(false);
        }}
        onConfirm={() => {
          supp_Pack();
        }}
      />
      <SuccesDialoge
        open={succesDelete}
        onClose={() => {
          setSuccesDelete(false);
          get_packs_();
        }}
        content="pack supprimer avec succes"
      />
      <ErreurDialoge
        open={errorDelete}
        onClose={() => {
          SetErrorDelete(false);
        }}
        content="Échec de supprimer du pack"
      />

      <Update_pack
        open={Pack_update}
        onclose={() => {
          setPack_update(false);
        }}
        send={send_update}
        id_pack={update_id}
      />
    <SuccesDialoge open={UpdateSucces} onClose={()=>{setUpdateSucces(false)}} content="le pack update avec succes"  />
    <ErreurDialoge open={UpdateError} onClose={()=>{setUpdateError(false)}} content="Échec update pack" />
      <BackLoading open={loading} />
    </>
  );
}
