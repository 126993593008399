import Navbar from "../../components/Appbar"
import { Box, Card, Paper, Typography } from "@mui/material";
import MiniDrawer from "../../components/draw";



export default function Home_commerceil(){
    return(
        <div style={{ background: "#EEEEEE", minHeight: "100vh" }}>
              <Navbar />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <MiniDrawer />
        <Box
          component="main"
          sx={{
            marginTop: "7%",
            width: "100%",
          }}
        >
          <Box
            component="main"
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "3%",
              marginLeft: "3%",
            }}
          >
            <h1>Home commerceil</h1>
          </Box>
          </Box>
          </Box>
        </div>
    )
}




