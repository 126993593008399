import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api_base from "../../api/Api";
import axios from "axios";

export const Vols_clients = createAsyncThunk("vols clients" , async(tag)=>{
    const response = await axios.get(`${Api_base}client/vols/pack_vols/${tag}`)
    return response.data
})

export const gettag = createAsyncThunk("get tag", async () => {
  const response = await axios.get(`${Api_base}tag/tags/pack/pack_vol`);
  return response.data;
});


const initialState = {
     loading:false,
     vols:[],
     tags:[]
  };

export const Vols_client_slice = createSlice({
    name:"Vols_client_slice",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
          builder.addCase(Vols_clients.pending , (state , action)=>{
            state.loading=true
          })
          builder.addCase(Vols_clients.fulfilled ,(state, action)=>{
            state.loading=false
            state.vols=action.payload
            console.log(action.payload)
          })
          builder.addCase(Vols_clients.rejected , (state, action)=>{
            state.loading=false
          })

          builder.addCase(gettag.pending, (state, action) => {
            state.loading = true;
          });
          builder.addCase(gettag.fulfilled, (state, action) => {
            state.loading = false;
            state.tags =[{tag:"tous"},...action.payload ];
          });
          builder.addCase(gettag.rejected, (state, action) => {
            state.loading = false;
          });
    }
})


export default Vols_client_slice.reducer