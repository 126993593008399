import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

function Token_expirate_alert({open , onConfirm}) {
  return (
    <Dialog open={open} >
      <DialogTitle>Token expirate</DialogTitle>
      <DialogContent>Votre token d'accès a expiré. Veuillez vous reconnecter pour continuer.</DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="error">
          ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Token_expirate_alert;