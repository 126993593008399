import {
  Autocomplete,
  Box,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Get_Fournisseurs,
  Get_pack,
  Get_pack_vols,
} from "../../store/admin/Pack_slice";
import { ContactSupportOutlined } from "@mui/icons-material";

export default function Update_pack({ open, onclose, send, id_pack }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Get_Fournisseurs());
    dispatch(Get_pack_vols());
  }, []);
  return (
    <Box>
      <Dialog open={open} onClose={onclose} fullScreen>
        <DialogTitle sx={{ fontSize: "40px" }}>Update pack</DialogTitle>
        <Page_ajouter onclose={onclose} send={send} />
      </Dialog>
    </Box>
  );
}

function Page_ajouter({ onclose, send }) {
  const { pack_vols } = useSelector((state) => state.pack_complete);
  const { fournisseurs } = useSelector((state) => state.pack_complete);
  const { pack } = useSelector((state) => state.pack_complete);
  const [fournisseur_id, setFournisseur_id] = useState(pack.fournisseur.id);
  const [Vols_id , setVols_id] = useState(pack.packs.pack_vol_id)
  const [fournisseur_nom ,setfournisseur_nom ]= useState(pack.fournisseur.nom)
  const [ pack_nom , setpack_nom] = useState(pack.packs.nom_pack_omra)
  const [errorFournisseur , SeterrorFournisseur ]= useState()
  const [errorPack , etErrorPack]=useState()

  function env(e) {
    e.preventDefault();
    if(!fournisseur_id){
      SeterrorFournisseur(true)
      return 
    }
    if(!Vols_id){
      setVols_id(true)
      return
    }
    const form = e.target;
    const data = {
      pack_nom: form.name_pack.value,
      desc: form.desc.value,
      id_fournisseur: parseInt(fournisseur_id),
      vols: parseInt(Vols_id),
      N_double: parseInt(form.N_double.value),
      P_double: parseFloat(form.P_double.value),
      N_TRP: parseInt(form.N_TRP.value),
      P_TRP: parseFloat(form.P_TRP.value),
      N_Quad: parseInt(form.N_Quad.value),
      P_Quad: parseFloat(form.P_Quad.value),
      N_Cinq: parseInt(form.N_Cinq.value),
      P_cinq: parseFloat(form.P_cinq.value),
    };
    setFournisseur_id();
    send(data);
  }

  return (
    <Box
      sx={{ marginLeft: "20%", marginRight: "20%" }}
      component="form"
      onSubmit={env}
    >
      <Grid container rowSpacing={2} columnSpacing={1}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder="nom pack"
            name="name_pack"
            required
           defaultValue={pack.packs?.nom_pack_omra}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            fullWidth
            sx={{ marginTop: "3%" }}
            id="fournisseur"
            options={fournisseurs}
            getOptionLabel={(p) => p.nom}
            openOnFocus
            value={{nom:fournisseur_nom}}
            isOptionEqualToValue={(option, value) => option.id == 1}
            onChange={(event, selectedOption, reason) => {
              if (selectedOption) {
                setFournisseur_id(selectedOption.id);
                setfournisseur_nom(selectedOption.nom)
              }
              if (reason === "clear") {
                setFournisseur_id();
                setfournisseur_nom("")
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="fournisseur"
                variant="outlined"
                required
                id="fournisseur"
                error={errorFournisseur}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            fullWidth
            sx={{ marginTop: "3%" }}
            id="pack vols"
            options={pack_vols}
            getOptionLabel={(p) => p.nom_packs_vols}
            openOnFocus
            value={{nom_packs_vols:pack_nom}}
            isOptionEqualToValue={(option, value) => option.type === value.type}
            onChange={(event, selectedOption, reason) => {
              if (selectedOption) {
                 setVols_id(selectedOption.id)
                 setpack_nom(selectedOption.nom_packs_vols)
              }
              if (reason === "clear") {
                setVols_id()
                setpack_nom("")
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="pack vols"
                name="pack_vol"
                variant="outlined"
                defaultValue={pack?.packs?.nom_packs_vols}
                required
                error={errorPack}
                id="pack vols"
                fullWidth
              />
            )}
          />
        </Grid>

        {pack.chamber ? (
          <>
            <>
              <Grid item xs={2}>
                <Typography variant="h5" marginTop="3px">
                  Double :
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  type="number"
                  placeholder="Number place double"
                  name="N_double"
                  defaultValue={pack?.chamber[0]?.place}
                  required
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  type="number"
                  placeholder="Prix place"
                  name="P_double"
                  required
                  defaultValue={pack?.chamber[0]?.prix}
                />
              </Grid>
            </>
            <>
              <Grid item xs={2}>
                <Typography variant="h5" marginTop="3px">
                  TRP :
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  type="number"
                  placeholder="Number place TRP"
                  name="N_TRP"
                  required
                  defaultValue={pack?.chamber[1]?.place}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  type="number"
                  placeholder="Prix place"
                  name="P_TRP"
                  required
                  defaultValue={pack?.chamber[1]?.prix}
                />
              </Grid>
            </>
            <>
              <Grid item xs={2}>
                <Typography variant="h5" marginTop="3px">
                  QUAD
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  type="number"
                  placeholder="Number place Quad"
                  name="N_Quad"
                  required
                  defaultValue={pack?.chamber[2]?.place}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  type="number"
                  placeholder="Prix place"
                  name="P_Quad"
                  required
                  defaultValue={pack?.chamber[2]?.prix}
                />
              </Grid>
            </>
            <>
              <Grid item xs={2}>
                <Typography variant="h5" marginTop="3px">
                  CINQ
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  type="number"
                  placeholder="Number place Cinq"
                  name="N_Cinq"
                  required
                  defaultValue={pack?.chamber[3]?.place}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  type="number"
                  placeholder="Prix place"
                  name="P_cinq"
                  required
                  defaultValue={pack?.chamber[3]?.prix}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth placeholder="desc" name="desc" required  defaultValue={pack.packs.desc_omra} />
              </Grid>
            </>
          </>
        ) : null}
      </Grid>
      <div style={{ position: "fixed", bottom: 30, right: 30 }}>
        <Button onClick={onclose}>Annuler</Button>

        <Button variant="contained" color="primary" type="submit">
          update
        </Button>
      </div>
    </Box>
  );
}
