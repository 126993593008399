import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Api_base from "../api/Api";
import axios from "axios";
import {instance} from "../api/Api_token";
import Cookies from "js-cookie";
import { Cookie, SatelliteAlt } from "@mui/icons-material";
import { create } from "@mui/material/styles/createTransitions";

export const Auth_register = createAsyncThunk("register", async (data) => {
  const response = await axios.post(`${Api_base}auth/register`, data);
  return response.data;
});
export const Auth_mailvalidate = createAsyncThunk("mail validation", async (data) => {
    const response = await axios.post(`${Api_base}auth/mailverification`, data);
    return response.data;
  }
);

export const Auth_validate = createAsyncThunk("validate compte" , async (data) =>{
    const response = await axios.post(`${Api_base}auth/mailvalidate`, data)
    return response.data
})

export const Auth_login= createAsyncThunk("login" , async (data)=>{
    const response = await axios.post(`${Api_base}auth/login`, data)
    return response.data
})

export const Auth_user = createAsyncThunk("user profile" , async (data)=>{
  const response = await instance.get("profile");
  return response.data
})

export const Auth_logout = createAsyncThunk("logout" , async ()=>{
     const response  = await instance.post(`/auth/logout` )
     return response.data
} );


const initialState = {
  info_user:[],
  isAuth:false,
  cookies:undefined,
  loading_auth: false,
  email_auth: null,
  erreur_code:null,
  drawapp:true,
  role:null,
};

export const Auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    auth:(state , action)=>{
      state.cookies=action.payload
      state.isAuth = state.cookies !== undefined
      state.loading_auth=true
    },changeDraw:(state,action)=>{
      state.drawapp=!state.drawapp
 },deconnection:(state , action)=>{
  Cookies.remove('token');
  Cookies.remove('token_expiration');
  Cookies.remove("role")
  window.location.reload();
 }

  },
  extraReducers: (builder) => {
    builder.addCase(Auth_register.pending, (state, action) => {
      state.loading_auth = true;
    });
    builder.addCase(Auth_register.fulfilled, (state, action) => {
      state.loading_auth = false;
    });
    builder.addCase(Auth_register.rejected, (state, action) => {
      state.loading_auth = false;
    });

       ////////////

    builder.addCase(Auth_mailvalidate.pending, (state, action) => {
      state.loading_auth = true;
    });
    builder.addCase(Auth_mailvalidate.fulfilled, (state, action) => {
      state.loading_auth = false;
    });
    builder.addCase(Auth_mailvalidate.rejected, (state, action) => {
      state.loading_auth = false;
    });

     ////////////

     builder.addCase(Auth_validate.pending, (state, action) => {
        state.loading_auth = true;
      });
      builder.addCase(Auth_validate.fulfilled, (state, action) => {
        state.loading_auth = false;
      });
      builder.addCase(Auth_validate.rejected, (state, action) => {
        state.loading_auth = false;
       
      });

      /////////////

      builder.addCase(Auth_login.pending, (state, action) => {
        state.loading_auth = true;
      });
      builder.addCase(Auth_login.fulfilled, (state, action) => {
        state.loading_auth = false;
      });
      builder.addCase(Auth_login.rejected, (state, action) => {
        state.loading_auth = false;
      });

      //////////


      builder.addCase(Auth_user.pending, (state, action) => {
        state.loading_auth = true;
      });
      builder.addCase(Auth_user.fulfilled, (state, action) => {
        state.loading_auth = false;
        state.isAuth=true
        state.info_user= action.payload;
        state.role =action.payload.role.type
        Cookies.set("role",action.payload.role.type)
      });
      builder.addCase(Auth_user.rejected, (state, action) => {
        state.loading_auth = false;
      });

      builder.addCase(Auth_logout.pending , (state ,action)=>{
         state.loading_auth = true 
      })
      builder.addCase(Auth_logout.fulfilled , (state ,action)=>{
        state.loading_auth = false 
     })
     builder.addCase(Auth_logout.rejected , (state ,action)=>{
      state.loading_auth = false 
   })

      

  },
});

export default Auth.reducer;
export const{auth , changeDraw , deconnection}=Auth.actions