import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import { Autocomplete } from "@mui/material";
import { company_data } from "../../data/vol_data";

export function Aj_up_vol({ open, onClose, onlick, sujet, choix, d_update }) {
  const [Nom, SetNom] = useState();
  const [company , setCompany] = useState();
  const [Origine, SetOrigine] = useState("");
  const [origine_ville , setorigine_ville] = useState("")
  const [origineaeroport , setorigineaeroport] = useState("")
  const [Destination, SetDestination] = useState("");
  const [destination_ville , setDestination_ville]=useState("")
  const [destinationAeroport , setDestinationAeroport]=useState("")
  const [Duree, SetDuree] = useState("01:00");
  const [PrixFournisseur, SetPrixFournisseur] = useState(0);
  const [Prix, SetPrix] = useState(0);
  const [DateDepart, SetDateDepart] = useState();
  const [DateArrivee, SetDateArrivee] = useState();

  const [ErreurDuree, SetErreurDuree] = useState();
  const [ErreurPrixFournisseur, SetErreurPrixFournisseur] = useState();
  const [ErreurPrix, SetErreurPrix] = useState();
  
  const [ErreurArrivee, SetErreurArrivee] = useState();
  const companys=company_data

  function inisial_erreur() {
    SetErreurDuree();
    SetErreurPrixFournisseur();
    SetErreurPrix();
    SetErreurArrivee();
  }
  function inisial_state() {
    const dateActuelle = new Date();
    SetNom("");
    setCompany("")
    SetOrigine("");
    setorigine_ville("")
    setorigineaeroport("")
    SetDestination("");
    setDestination_ville("")
    setDestinationAeroport("")
    SetDuree("01:00");
    SetPrix(0);
    SetPrixFournisseur(0);
     SetDateArrivee(dateActuelle);
    SetDateDepart(dateActuelle);
    inisial_erreur();
  }

  function valide(e) {
    e.preventDefault();
    inisial_erreur();
    const form = e.target;
    const prix_f = parseFloat(PrixFournisseur);
    const prix = parseFloat(Prix);
    const [hours, mins] = Duree.split(":");
    const totalMinutes = parseInt(hours, 10) * 60 + parseInt(mins, 10);

    if (totalMinutes <= 0) {
      SetErreurDuree("La durée doit être supérieure à zéro.");
      return;
    }

    // Validation du prix fournisseur

    if (prix_f <= 0) {
      SetErreurPrixFournisseur(
        "Le prix fournisseur doit être supérieur à zéro."
      );
      return;
    }

    // Validation du prix
    if (prix <= 0) {
      SetErreurPrix("Le prix doit être supérieur à zéro.");
      return;
    }

    // Comparaison du prix fournisseur et du prix
    if (prix < prix_f) {
      SetErreurPrix("Le prix ne peut pas être inférieur au prix fournisseur.");
      return;
    }

    const DateD = new Date(DateDepart);
    const DateA = new Date(DateArrivee);
    const differenceEnMillisecondes = DateA - DateD;
    const minutes = Math.floor(differenceEnMillisecondes / (1000 * 60));
    if (totalMinutes > minutes) {
      SetErreurArrivee(
        "L'heure d'arrivée doit être postérieure à la durée + l'heure de départ."
      );

      return;
    }

    const data = {
      nom: Nom,
      company:company,
      date_depart: DateDepart,
      date_arrivee: DateArrivee,
      destination: Destination,
      ville_destination:destination_ville,
      aéroport_destination:destinationAeroport,
      Origine: Origine,
      aéroport_Origine:origineaeroport,
      ville_Origine:origine_ville,
      Prix: Prix,
      prix_fournisseur: PrixFournisseur,
      duree: Duree,
    };
    onlick(data);
    inisial_state();
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle sx={{ fontSize: "40px" }}>{sujet}</DialogTitle>
      <Box
        
        component="form"
        onSubmit={valide}
        sx={{ width: "80%", marginLeft: "10%" }}
        Validate
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="Num vol"
              name="nom"
              autoComplete="Num vol"
              autoFocus
              value={Nom}
              onChange={(e) => {
                SetNom(e.target.value);
              }}
              inputProps={{
                maxLength: 15,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
               sx={{marginTop:"3%"}}
              id="company"
              options={companys}
              getOptionLabel={(companys) => companys.company}
              openOnFocus
              onChange={(event, selectedOption, reason) => {
                if (selectedOption) {
                   setCompany(selectedOption.company)
                }
                if (reason === "clear") {
                       setCompany()
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="company"
                  variant="outlined"
                  required
                  id="type"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="origine"
              name="origine"
              autoComplete="origine"
              autoFocus
              value={Origine}
              onChange={(e) => {
                SetOrigine(e.target.value);
              }}
              inputProps={{
                maxLength: 15, // Longueur maximale autorisée
              }}
            />
          </Grid>
          <Grid item xs={4}>
          <TextField
              margin="normal"
              required
              fullWidth
              label="origine ville"
              name="origine_ville"
              autoComplete="origine ville"
              autoFocus
              value={origine_ville}
              onChange={(e) => {
                setorigine_ville(e.target.value);
              }}
              inputProps={{
                maxLength: 15, // Longueur maximale autorisée
              }}
            />

          </Grid>
          <Grid item xs={4}>
          <TextField
              margin="normal"
              required
              fullWidth
              label="origine aéroport"
              name="origine_aéroport"
              autoComplete="origine aéroport"
              autoFocus
              value={origineaeroport}
              onChange={(e) => {
                setorigineaeroport(e.target.value);
              }}
              inputProps={{
                maxLength: 15, // Longueur maximale autorisée
              }}
            />

          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="destination"
              name="destination"
              autoComplete="destination"
              autoFocus
              value={Destination}
              onChange={(e) => {
                SetDestination(e.target.value);
              }}
              inputProps={{
                maxLength: 15, // Longueur maximale autorisée
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="destination ville"
              name="destination_ville"
              autoComplete="destination ville"
              autoFocus
              value={destination_ville}
              onChange={(e) => {
                setDestination_ville(e.target.value);
              }}
              inputProps={{
                maxLength: 15, // Longueur maximale autorisée
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="destination aéroport"
              name="destination_aéroport"
              autoComplete="destination_aéroport"
              autoFocus
              value={destinationAeroport}
              onChange={(e) => {
                setDestinationAeroport(e.target.value);
              }}
              inputProps={{
                maxLength: 15, // Longueur maximale autorisée
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="duree"
              name="duree"
              autoComplete="duree"
              type="time"
              autoFocus
              error={ErreurDuree}
              helperText={ErreurDuree ? ErreurDuree : ""}
              value={Duree}
              onChange={(e) => {
                SetDuree(e.target.value);
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">h</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="prix fournisseur"
              name="prix fournisseur"
              autoComplete="prix fournisseur"
              type="number"
              autoFocus
              error={ErreurPrixFournisseur}
              helperText={ErreurPrixFournisseur ? ErreurPrixFournisseur : ""}
              value={PrixFournisseur}
              onChange={(e) => {
                SetPrixFournisseur(e.target.value);
              }}
              InputProps={{
                maxLength: 6,
                endAdornment: (
                  <InputAdornment position="end">dh</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              margin="normal"
              required
              fullWidth
              label="prix"
              name="prix"
              autoComplete="prix"
              type="number"
              autoFocus
              error={ErreurPrix}
              helperText={ErreurPrix ? ErreurPrix : ""}
              value={Prix}
              onChange={(e) => {
                SetPrix(e.target.value);
              }}
              InputProps={{
                maxLength: 6,
                endAdornment: (
                  <InputAdornment position="end">dh</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h3>date depart :</h3>{" "}
              <TextField
                type="datetime-local"
                required
                value={DateDepart}
                onChange={(e) => {
                  SetDateDepart(e.target.value);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h3>date arrivee:</h3>{" "}
              <TextField
                type="datetime-local"
                error={ErreurArrivee}
                helperText={ErreurArrivee ? ErreurArrivee : ""}
                required
                value={DateArrivee}
                onChange={(e) => {
                  SetDateArrivee(e.target.value);
                }}
              />
            </div>
          </Grid>
        </Grid>
        <div style={{ position: "fixed", bottom: 30, right: 30 }}>
          <Button onClick={onClose}>Annuler</Button>
          <Button variant="contained" type="submit" color="primary">
            {choix}
          </Button>
        </div>
      </Box>
    </Dialog>
  );
}
