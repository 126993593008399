import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {instance} from "../../api/Api_token";


export const get_packs_name = createAsyncThunk("get pack", async () => {
   const response = await instance.get(`commerciel/resrevation/get_pack`);
   return response.data;
 });
 
 /*export const new_resrevation = createAsyncThunk("new resrevation", async(data)=>{
   const response = await instance.post(`commerciel/resrevation/new_resrevation`, data)
   return response
 })*/

 export const New_reservation = createAsyncThunk("new resrvation", async (data) => {
   const response = await instance.post(`commerciel/resrevation/new_resrevation`, data);
   return response.data;
 });

 export const Get_resrevation=createAsyncThunk("get resrvation" , async(data)=>{
   const response = await instance.get(`commerciel/resrevation/get_resrevation/${data.num}?page=${data.page+1}&pack=${data?.pack}&etat=${data?.etat}&nom=${data?.nom}&exp=${data?.exp}&societe=${data?.societe}`)
   return response.data
 })

export const Get_pack_filter = createAsyncThunk ("get packs filter" , async()=>{
   const response = await instance.get(`commerciel/resrevation/get_packs_filter`)
   return response.data
})

export const delete_reservation = createAsyncThunk("delete resrvation" , async(id)=>{
     const response = await instance.delete(`commerciel/resrevation/detele_reservation/${id}`)
     return response.data
})

export const Get_info_reservation = createAsyncThunk("info reservation" , async(id)=>{
     const response = await instance.get(`commerciel/resrevation/info_reservation/${id}`)
     return response.data
})
export const Get_info_reservation_update = createAsyncThunk("info reservation update" , async(id)=>{
  const response = await instance.get(`commerciel/resrevation/info_reservation/${id}`)
  return response.data
})

export const Update_resrvation = createAsyncThunk("update resrevation" , async(data)=>{
   const response = await instance.post(`commerciel/resrevation/update/${data.id}`, data)
   return response.data
})

 const initialState={
    loading:false,
     packs:[],
     packs_filter:[],
     resrevation:null,
     info:[],
     update_info:[]
}


export const Reservation_slice=createSlice({
    name:"reservation",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
       builder.addCase(get_packs_name.pending , (state , action)=>{
          state.loading=true
       })
       builder.addCase(get_packs_name.fulfilled , (state , action)=>{
        state.loading=false
       
        state.packs=action.payload
     })
     builder.addCase(get_packs_name.rejected , (state , action)=>{
        state.loading=false
     })

     builder.addCase(New_reservation.pending , (state , action)=>{
      state.loading=true
     })
     builder.addCase(New_reservation.fulfilled , (state, action)=>{
      state.loading=false
     })
     builder.addCase(New_reservation.rejected , (state, action)=>{
      state.loading=false
     })

     builder.addCase(Get_resrevation.pending , (state , action)=>{
       state.loading=true
     })
     builder.addCase(Get_resrevation.fulfilled , (state , action)=>{
       state.loading=false
        state.resrevation=action.payload
     })
     builder.addCase(Get_resrevation.rejected , (state, action)=>{
      state.loading=false
     })

     builder.addCase(Get_pack_filter.pending ,(state , action)=>{
       state.loading=true
     })
     builder.addCase(Get_pack_filter.fulfilled ,(state, action)=>{
       state.loading=false
       state.packs_filter=action.payload
     })
     builder.addCase(Get_pack_filter.rejected ,(state, action)=>{
      state.loading=false
    })

    builder.addCase(delete_reservation.pending , (state , action)=>{
      state.loading=true
    })
    builder.addCase(delete_reservation.fulfilled , (state, action)=>{
       state.loading=false
    })
    builder.addCase(delete_reservation.rejected , (state, action)=>{
      state.loading=false
   })
   builder.addCase(Get_info_reservation.pending , (state, action)=>{
     state.loading = true
   })
   builder.addCase(Get_info_reservation.fulfilled , (state , action)=>{
    state.loading=false
    state.info=action.payload
   })
   builder.addCase(Get_info_reservation.rejected , (state, action)=>{
    state.loading=false
   })
     builder.addCase(Get_info_reservation_update.pending ,(state, action)=>{
      state.loading=true
     })
     builder.addCase(Get_info_reservation_update.fulfilled ,(state , action)=>{
      state.loading=false
      state.update_info=action.payload
     })
     builder.addCase(Get_info_reservation_update.rejected , (state , action)=>{
      state.loading=false
     })

     builder.addCase(Update_resrvation.pending ,(state , action)=>{
      state.loading=true
     })
     builder.addCase(Update_resrvation.fulfilled , (state , action)=>{
      state.loading=false
     })
     builder.addCase(Update_resrvation.rejected ,(state ,action)=>{
      state.loading=false
     })
   
    }
})


export default Reservation_slice.reducer