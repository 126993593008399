import { Dialog, DialogTitle, DialogActions } from "@mui/material";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
function Info_fournisseur({ open, onclose }) {
  const { fournisseur } = useSelector((state) => state.fournisseur);

  return (
    <Dialog open={open} onClose={onclose}>
      <DialogTitle>
        <img
          src={fournisseur?.path}
          height="auto"
          width="100%"
          style={{ minWidth: "300px" }}
        />
      </DialogTitle>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <p>
            <b>Nom :</b>
            {fournisseur?.nom}
          </p>
        </Grid>
        <Grid item xs={6}>
          <p>
            <b> type :</b>
            {fournisseur?.type}
          </p>
        </Grid>
        <Grid item xs={6}>
          <p>
            <b >Email :</b>
            {fournisseur?.email}
          </p>
        </Grid>
        <Grid item xs={6}>
          <p>
            <b>Number:</b>
            {fournisseur?.number}
          </p>
        </Grid>
        <Grid item xs={6}>
          <p><b>Adress : </b>{fournisseur?.adress}</p>
        </Grid>
        <Grid item xs={6}>
          <p>
            <b>localisation :</b>
            {fournisseur?.pays}-{fournisseur?.ville} -{" "}
            <a href={fournisseur?.maps} target="_blank" rel="noreferrer">
              maps
            </a>
          </p>
        </Grid>
        <Grid item xs={12}>
          <p><b>description : </b>{fournisseur?.description}</p>
        </Grid>
      </Grid>
      <DialogActions>
        <Button onClick={onclose}>Annuler</Button>
      </DialogActions>
    </Dialog>
  );
}

export default Info_fournisseur;
