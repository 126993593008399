import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Box, Button, Grid, selectClasses } from "@mui/material";
import { useState } from "react";
import { Autocomplete } from "@mui/material";
import { useEffect } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import { New_pack, gettag } from "../../store/admin/Pack_Vols_slice";
import { formToJSON } from "axios";
import { company_data } from "../../data/vol_data";

export default function Add_pack({ open, onClose, onlik }) {
  const [data, setData] = useState();
  function env(p) {
    onlik(p);
  }

  return (
    <Box>
      <Dialog open={open} onClose={onClose} fullScreen>
        <DialogTitle sx={{ fontSize: "40px" }}>ajouter pack</DialogTitle>
        <Ajouter_pack env={env} onclose={onClose} />
      </Dialog>
    </Box>
  );
}

export function Ajouter_pack(props) {
  const { env } = props;
  const { onclose } = props;
  const types = [{ type: "direct " }, { type: "escale" }];
  const company = company_data;
  const dispatch = useDispatch();
  const [typeAller, settypeAller] = useState("");
  const [typeRetour, settypeRetour] = useState("");
  const [selectCompany, setSelectComapny] = useState("");
  const [selectedValue, setSelectedValue] = useState("Aller_simple");
  const options = useSelector((state) => state.pack.tags);
  const [selectOr, SetSelectOr] = useState("");
  const [selectdes, SetSelectDes] = useState("");
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const airport = [
    { nom: "DJEDDAH (JED) - Arabie Saoudite" },
    { nom: "CASABLANCA (CMN) - Maroc" },
  ];
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const handleSelect = (event, newValue) => {
    setSelectedOptions(newValue);
  };
  useEffect(() => {
    dispatch(gettag());
  }, []);

  function ajouter(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData();
    formData.append("nom", form.nom.value);
    formData.append("type", selectedValue);
    formData.append("type_aller", typeAller);
    formData.append("date_aller", form.date_aller.value);
    formData.append("airport_aller", selectOr);
    formData.append("prix", form.prix.value);
    formData.append("place", form.place.value);
    formData.append("desc", form.desc.value);
    formData.append("tags", JSON.stringify(selectedOptions));
    formData.append("airport_retour", selectdes);
    formData.append("company", selectCompany);
    formData.append("N_vols_A" ,form.number_aller.value)
    
    if (selectedValue === "Aller_Retour") {
      formData.append("type_retour", typeRetour);
      formData.append("date_retour", form.date_retour.value);
      formData.append("N_vols_r" , form.number_retour.value)
    }
    env(formData);
    inisial_state();
  }

  function inisial_state() {
    settypeAller("");
    settypeRetour("");
    setSelectedValue("Aller_simple");
    setSelectedOptions([]);
  }

  return (
    <Box
      component="form"
      onSubmit={ajouter}
      sx={{ width: "60%", marginLeft: "20%" }}
    >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Nom pack"
            name="nom"
            autoComplete="Nom vol"
            autoFocus
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">type</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="type_vol"
              value={selectedValue}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="Aller_simple"
                control={<Radio />}
                label="Aller simple"
              />
              <FormControlLabel
                value="Aller_Retour"
                control={<Radio />}
                label="Aller / Retour"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <h3>Aller</h3>
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            id="type aller"
            options={types}
            getOptionLabel={(types) => types.type}
            openOnFocus
            isOptionEqualToValue={(option, value) => option.type === value.type}
            onChange={(event, selectedOption, reason) => {
              if (selectedOption) {
                settypeAller(selectedOption.type);
              }
              if (reason === "clear") {
                settypeAller("");
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="type de vol aller"
                variant="outlined"
                required
                id="type aller"
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
          id="n_vols_a"
            label="number de vols"
            variant="outlined"
            placeholder=" number de vols"
            name="number_aller"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            type="datetime-local"
            name="date_aller"
            required
            fullWidth
            variant="outlined"
            label="date depart"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {selectedValue == "Aller_Retour" ? (
          <>
            <Grid item xs={12}>
              <h3>Retour</h3>
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id="type retour"
                options={types}
                getOptionLabel={(types) => types.type}
                openOnFocus
                isOptionEqualToValue={(option, value) =>
                  option.type === value.type
                }
                onChange={(event, selectedOption, reason) => {
                  if (selectedOption) {
                    settypeRetour(selectedOption.type);
                  }
                  if (reason === "clear") {
                    settypeRetour("");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="type de vol retour"
                    variant="outlined"
                    required
                    id="type retour"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
              id="n_vols_r"
                label="number de vols"
                variant="outlined"
                placeholder=" number de vols"
                name="number_retour"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                type="datetime-local"
                name="date_retour"
                required
                label="date Retour"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </>
        ) : null}

        <Grid item xs={6}>
          <Autocomplete
            sx={{ marginTop: "3%" }}
            id="airport origine"
            options={airport}
            getOptionLabel={(airport) => airport.nom}
            openOnFocus
            isOptionEqualToValue={(option,value)=>option.nom === value.nom}
            onChange={(event, selectedOption, reason) => {
              if (selectedOption) {
                SetSelectOr(selectedOption.nom);
              }
              if (reason === "clear") {
                SetSelectOr("");
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="origine airport"
                variant="outlined"
                required
                id="origine airport "
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Autocomplete
            sx={{ marginTop: "3%" }}
            id="airport destination"
            options={airport}
            getOptionLabel={(airport) => airport.nom}
            openOnFocus
            isOptionEqualToValue={(option,value)=>option.nom === value.nom}
            onChange={(event, selectedOption, reason) => {
              if (selectedOption) {
                SetSelectDes(selectedOption.nom);
              }
              if (reason === "clear") {
                SetSelectDes("");
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="airport destination "
                variant="outlined"
                required
                id="airport destination"
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            type="number"
            label="prix"
            name="prix"
            required
            fullWidth
            InputProps={{
              maxLength: 6,
              endAdornment: <InputAdornment position="end">dh</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            label="place"
            name="place"
            required
            fullWidth
            InputProps={{
              maxLength: 6,
              endAdornment: (
                <InputAdornment position="end">place</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            id="company"
            options={company}
            getOptionLabel={(company) => company.company}
            openOnFocus
            isOptionEqualToValue={(option, value) =>
              option.company === value.company
            }
            onChange={(event, selectedOption, reason) => {
              if (selectedOption) {
                setSelectComapny(selectedOption.company);
              }
              if (reason === "clear") {
                setSelectComapny("");
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="company"
                variant="outlined"
                required
                id="company"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            multiple
            id="multi-value-input"
            options={options}
            getOptionLabel={(option) => option.name}
            value={selectedOptions}
            onChange={handleSelect}
            renderInput={(params) => (
              <TextField {...params} label="Select Multiple tags" name="tags" />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            label="description"
            name="desc"
            required
            fullWidth
          />
        </Grid>
      </Grid>
      <div style={{ position: "fixed", bottom: 30, right: 30 }}>
        <Button onClick={onclose}>Annuler</Button>

        <Button variant="contained" color="primary" type="submit">
          ajouter
        </Button>
      </div>
    </Box>
  );
}
