
import { Token } from "@mui/icons-material";
import axios from "axios";
import Cookies from "js-cookie";
import Api_base from "./Api";


const instance = axios.create({
   // baseURL: 'https://agence.nusuk.ma/api/',
   // baseURL: 'http://127.0.0.1:8000/api/',
   baseURL:Api_base,
   // timeout: 1000,
    headers: {
      'Content-Type': 'multipart/form-data',
     
    }
  });

  function updateAuthToken(token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  
  
  const authToken = Cookies.get("token");
  if (authToken !== undefined) {
    updateAuthToken(authToken);
  }
export { instance, updateAuthToken };