import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Navbar from "../../components/Appbar";
import MiniDrawer from "../../components/draw";
import { Box, Card, Paper, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Skeleton } from "@mui/material";
import { IconButton } from "@mui/material";
import {
  delete_fournisseur,
  get_fournisseur,
  get_fournisseurs,
  get_pays,
  get_type,
  get_ville,
  new_fournisseur,
  up_fournisseur,
} from "../../store/admin/fournisseur_slice";
import DeleteConfirmationDialog from "../../model/DeleteConfirme";
import SuccesDialoge from "../../model/succes";
import BackLoading from "../page_tous/back_loading";
import ErreurDialoge from "../../model/Erreur";
import FournisseurForm from "../../model/Fournisseur/Aj_up_fournisseur";
import { TableContainer } from "@mui/material";
import { TablePagination } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

import Info_fournisseur from "../../model/Fournisseur/info_fournisseur";

export default function Fournisseur() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [deleteC, Setdeletec] = useState(false);
  const [id_delete, SetId_delete] = useState();
  const [succes, SetSucces] = useState(false);
  const [ajouter, SetAjouter] = useState(false);
  const [succes_ajouter, setSucces_ajouter] = useState(false);
  const [error_ajouter, seterror_ajouter] = useState(false);
  const [Id_update, setId_update] = useState();
  const [update, setupdate] = useState(false);
  const [succes_update, setSucces_update] = useState(false);
  const [error_update, setError_update] = useState(false);
  const [order, setOrder] = useState("");
  const [info , setinfo] = useState(false)
  const { loading_fournisseur_back } = useSelector(
    (state) => state.fournisseur
  );
  const [error, seterror] = useState(false);
  const { data } = useSelector((state) => state.fournisseur.fournisseurs);
  const { type } = useSelector((state) => state.fournisseur);
  const { pays } = useSelector((state) => state.fournisseur);
  const { ville } = useSelector((state) => state.fournisseur);
  const [select_type, setSelectType] = useState(null);
  const [select_pays, setSelectPays] = useState(null);
  const [select_ville, setSelectVille] = useState(null);

  useEffect(() => {
    get_select();
  }, []);

  useEffect(() => {
    get_fournisseur__();
  }, [select_pays, select_type, select_ville, page, rowsPerPage, order]);

  function get_select() {
    dispatch(get_type());
    dispatch(get_pays());
    dispatch(get_ville());
  }

  function get_fournisseur__() {
    const dt = {
      page: page,
      num: rowsPerPage,
      ville: select_ville,
      pays: select_pays,
      type: select_type,
      order: order,
    };
    dispatch(get_fournisseurs(dt));
  }

  function supprimer_fournisseur() {
    Setdeletec(false);
    dispatch(delete_fournisseur(id_delete)).then((result) => {
      if (delete_fournisseur.fulfilled.match(result)) {
        SetSucces(true);
        SetId_delete();
      }
      if (delete_fournisseur.rejected.match(result)) {
        seterror(true);
        SetId_delete();
      }
    });
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function ajouter_fournisseur(data) {
    console.log(data.get("image"));

    SetAjouter(false);

    dispatch(new_fournisseur(data)).then((result) => {
      if (new_fournisseur.fulfilled.match(result)) {
        setSucces_ajouter(true);
        get_fournisseur__();
      }
      if (new_fournisseur.rejected.match(result)) {
        seterror_ajouter(true);
      }
    });
  }

  function update_fournisseur(data) {
    setupdate(false);

    dispatch(up_fournisseur(data)).then((result) => {
      if (up_fournisseur.fulfilled.match(result)) {
        setSucces_update(true);
      }
      if (up_fournisseur.rejected.match(result)) {
        setError_update(true);
      }
      setId_update();
    });
  }

  function data_update(id) {
    dispatch(get_fournisseur(id)).then((result) => {
      if (get_fournisseur.fulfilled.match(result)) {
        setupdate(true);
        setId_update(id);
      }
    });
  }
  function data_info(id) {
    dispatch(get_fournisseur(id)).then((result) => {
      if (get_fournisseur.fulfilled.match(result)) {
        setinfo(true);
        
      }
    });
  }
  return (
    <div
      style={{ background: "#EEEEEE", minHeight: "100vh" }}
    >
      <Navbar />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <MiniDrawer />
        <Box
          component="main"
          sx={{
            marginTop: "7%",
            width: "100%",
          }}
        >
          <Box
            component="main"
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "3%",
              marginLeft: "3%",
            }}
          >
            <Card>
              <Paper>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" colSpan={2}>
                          <Typography variant="h4">
                            <b>fournisseur</b>
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Autocomplete
                            id="type"
                            openOnFocus
                            options={type && type}
                            getOptionLabel={(type) => type.type}
                            onChange={(event, selectedOption, reason) => {
                              if (selectedOption) {
                                setSelectType(selectedOption.type);
                                setPage(0);
                              }
                              if (reason === "clear") {
                                setSelectType(null);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="type"
                                variant="standard"
                                id="type"
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Autocomplete
                            id="pays"
                            openOnFocus
                            options={pays}
                            getOptionLabel={(pays) => pays.pays}
                            onChange={(event, selectedOption, reason) => {
                              if (selectedOption) {
                                setSelectPays(selectedOption.pays);
                                dispatch(get_ville(selectedOption.pays));
                                setPage(0);
                              }
                              if (reason === "clear") {
                                setSelectPays(null);
                                dispatch(get_ville());
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="pays"
                                variant="standard"
                                id="pays"
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Autocomplete
                            id="ville"
                            openOnFocus
                            options={ville}
                            getOptionLabel={(ville) => ville.ville}
                            onChange={(event, selectedOption, reason) => {
                              if (selectedOption) {
                                setSelectVille(selectedOption.ville);
                                setPage(0);
                              }
                              if (reason === "clear") {
                                setSelectVille(null);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="ville"
                                variant="standard"
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                        <TextField id="standard-basic" label="nom" variant="standard" value={order}  onChange={(e)=>{setOrder(e.target.value)}}/>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            variant="contained"
                            onClick={() => {
                              SetAjouter(true);
                            }}
                          >
                            +add
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>Name</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>number</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>Type</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>pays</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>ville</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>maps</b>
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>action</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data
                        ? data?.data?.map((f) => (
                            <TableRow
                              key={f.id}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                            >
                              <TableCell align="center">{f?.nom}</TableCell>
                              <TableCell align="center">{f?.number}</TableCell>
                              <TableCell align="center">{f?.type}</TableCell>
                              <TableCell align="center">{f?.pays}</TableCell>
                              <TableCell align="center">{f?.ville}</TableCell>

                              <TableCell align="center">
                                <a
                                  href={f?.maps}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  localisation
                                </a>
                              </TableCell>

                              <TableCell align="center">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <IconButton
                                    aria-label="info"
                                    color="secondary"
                                    size="small"
                                    onClick={()=>{
                                      data_info(f?.id)
                                    }}
                                  >
                                    
                                    <InfoIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="update"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                      data_update(f?.id);}}
                                  >
                                    <ModeEditOutlineIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="delete"
                                    size="small"
                                    color="error"
                                    onClick={() => {
                                      Setdeletec(true);
                                      SetId_delete(f.id);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))
                        : Array.from({ length: 6 }, (_, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <TablePagination
                rowsPerPageOptions={[5, 25, 100]}
                component="div"
                count={data?.total ?? 1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Box>
        </Box>
      </Box>

      <DeleteConfirmationDialog
        open={deleteC}
        onClose={() => {
          Setdeletec(false);
          SetId_delete();
        }}
        onConfirm={supprimer_fournisseur}
        content="Êtes-vous sûr de vouloir supprimer ce fournisseur ?"
      />

      <SuccesDialoge
        open={succes}
        onClose={() => {
          SetSucces(false);
          get_fournisseur__();
        }}
        content="le fournisseur supprimer avec succes"
      />

      <ErreurDialoge
        open={error}
        onClose={() => {
          seterror(false);
        }}
        content="le fournisseur ne pas supprimer"
      />

      <FournisseurForm
        open={ajouter}
        onClose={() => {
          SetAjouter(false);
        }}
        onlick={ajouter_fournisseur}
        sujet="Ajouter Fournisseur"
        choix="ajouter"
      />

      <SuccesDialoge
        open={succes_ajouter}
        onClose={() => {
          setSucces_ajouter(false);
          get_fournisseur__();
          get_select();
        }}
        content="le fournisseur ajouter avec succes"
      />

      <ErreurDialoge
        open={error_ajouter}
        onClose={() => {
          seterror_ajouter(false);
        }}
        content="le fournisseur ne pas ajouter"
      />

      <FournisseurForm
        open={update}
        onClose={() => {
          setupdate(false);
          setId_update();
        }}
        onlick={update_fournisseur}
        sujet="update fournisseur"
        choix="update"
        d_update={Id_update}
      />

      <SuccesDialoge
        open={succes_update}
        onClose={() => {
          setSucces_update(false);
          get_fournisseur__();
          get_select();
        }}
        content="le fournisseur update avec succes"
      />

      <ErreurDialoge
        open={error_update}
        onClose={() => {
          setError_update(false);
        }}
        content="le fournisseur ne pas change"
      />

      <Info_fournisseur
      open={info}
      onclose={()=>{
        setinfo(false)
      }}
       />

      <BackLoading open={loading_fournisseur_back} />
    </div>
  );
}
