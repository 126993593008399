import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import { Provider } from 'react-redux';
import { Store } from './store/Store';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
    <Provider store={Store}>
    <App />
    </Provider>
    </I18nextProvider>
  </React.StrictMode>
);



