import * as React from 'react';
import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';

export default function CircularIndeterminate() {
  return (
    <Box       sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 9999, 
    }}>
      <CircularProgress />
    </Box>
  );
}