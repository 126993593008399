import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../api/Api_token";
import download from "downloadjs";
import { pdf } from "@react-pdf/renderer";



export const GetResrevationOmracomptable = createAsyncThunk("omra comptable" , async(data)=>{
      const response = await instance.get(`comptable/reservation/omra/getreservation/${data.row}?page=${data.page+1}`)
      return response.data
})

export const getInforeservation =  createAsyncThunk(" info reservation " , async(id)=>{
  const response = await instance.get(`comptable/reservation/omra/getInfoReservation/${id}`)
  return response.data
})

export const payePackOmara = createAsyncThunk (" paye pack omra" , async (data)=>{
  const response = await instance.post(`comptable/reservation/omra/paye_pack` , data)
  return response.data
})

export const PdfPackOmra = createAsyncThunk("pdf omra", async(id ,thunkAPI)=>{

  try {
    const response = await instance.get(`comptable/reservation/omra/PdfOmra/${id}`, {
      responseType: 'blob',
    });

    const contentType = response.headers['content-type']; 
    download(response.data, 'facture.pdf', contentType);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }});

const initialState = {
    loading: false,
    resrevationOmra : []  ,
    Inforesrvation :{}
  };
  
  export const Paye_Omra_slice = createSlice({
    name: "paye omra comptable",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
     builder.addCase(GetResrevationOmracomptable.pending ,(state , action)=>{
         state.loading = true
     })
     builder.addCase(GetResrevationOmracomptable.fulfilled , (state , action)=>{
      state.loading = false
      state.resrevationOmra = action.payload
    
     })
     builder.addCase(GetResrevationOmracomptable.rejected , (state , action)=>{
      state.loading = false
     })
     builder.addCase(getInforeservation.pending ,(state , action)=>{
      state.loading = true
  })
  builder.addCase(getInforeservation.fulfilled , (state , action)=>{
   state.loading = false
   state.Inforesrvation = action.payload
 
  })
  builder.addCase(getInforeservation.rejected , (state , action)=>{
   state.loading = false
  })
  builder.addCase(payePackOmara.pending , (state, action)=>{
    state.loading = true
  })
  builder.addCase(payePackOmara.fulfilled , (state , action)=>{
    state.loading = false
  })
  builder.addCase(payePackOmara.rejected , (state , action) =>{
    state.loading = false
  })
  builder.addCase(PdfPackOmra.pending , (state , action)=>{
    state.loading = true
  })
  builder.addCase(PdfPackOmra.fulfilled , (state , action)=>{
    state.loading = false
  })
  builder.addCase(PdfPackOmra.rejected , (state , action)=>{
    state.loading = false
  })
    },
  });
  
  export default Paye_Omra_slice.reducer;
  