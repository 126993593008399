import Navbar from "../../components/Appbar";
import { Box, Card, Paper, Typography } from "@mui/material";
import MiniDrawer from "../../components/draw";
import { TableContainer } from "@mui/material";
import { TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Skeleton } from "@mui/material";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { filetr_etats2 } from "../../data/vol_data";
import { useDispatch, useSelector } from "react-redux";
import BackLoading from "../page_tous/back_loading";
import InfoIcon from "@mui/icons-material/Info";
import DownloadIcon from "@mui/icons-material/Download";
import {
  fetchPdf,
  get_Client_name,
  get_Pack_name,
  get_reservation_comptable,
  get_statisctique_pack,
} from "../../store/comptable/paye_slice";
import PaidIcon from "@mui/icons-material/Paid";
import { Link } from "react-router-dom";
import { GetResrevationOmracomptable, PdfPackOmra } from "../../store/comptable/paye_omra_slice";
export default function Pack_Omra_comptable() {
  const dispatch = useDispatch();
  // paginate

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  // filter   const { loading } = useSelector((state) => state.reservation);
  const { resrevationOmra } = useSelector((state) => state.payeOmra);
  const { clients } = useSelector((state) => state.paye);
  const { pack_filter } = useSelector((state) => state.paye);
  const { statistique_pack } = useSelector((state) => state.paye);
  const [select_pack, SetSelect_pack] = useState();
  const [id_client, setId_client] = useState();
  const filterEtat = filetr_etats2;
  const [select_etat, SetSelect_etat] = useState();
  const { loading } = useSelector((state) => state.payeOmra);

  useEffect(() => {
   // dispatch(get_Client_name());
    //dispatch(get_Pack_name());
  }, []);

  useEffect(() => {
    get_data();
  }, [rowsPerPage, page, select_pack, select_etat, id_client]);

  useEffect(() => {
    dispatch(get_statisctique_pack(select_pack));
  }, [select_pack]);

  function get_data() {
    const data = {
      row: rowsPerPage,
      page: page,
      pack: select_pack,
      etat: select_etat,
      client: id_client,
    };
    dispatch(GetResrevationOmracomptable(data));
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    console.log(event.target.value);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  console.log(resrevationOmra);
  return (
    <div style={{ background: "#EEEEEE", minHeight: "100vh" }}>
      <Navbar />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <MiniDrawer />
        <Box
          component="main"
          sx={{
            marginTop: "7%",
            width: "100%",
          }}
        >
          <Box
            component="main"
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "3%",
              marginLeft: "3%",
            }}
          >
            <Card>
              <Paper>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" colSpan={2}>
                          <Typography variant="h4">
                            <b>Réservation</b>
                          </Typography>
                        </TableCell>
                        <TableCell colSpan={2}>
                          <Autocomplete
                            id="clients"
                            openOnFocus
                            options={clients}
                            getOptionLabel={(c) =>
                              `${c.nom_client} - ${c.prenom} -${c.telephone}`
                            }
                            onChange={(event, selectedOption, reason) => {
                              if (selectedOption) {
                                setId_client(selectedOption.id);
                                setPage(0);
                              }
                              if (reason === "clear") {
                                setId_client();
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Clients"
                                variant="standard"
                                id="clients"
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell colSpan={2}>
                          <Autocomplete
                            id="Pack"
                            openOnFocus
                            options={pack_filter}
                            getOptionLabel={(c) => c.nom_packs_vols}
                            onChange={(event, selectedOption, reason) => {
                              if (selectedOption) {
                                SetSelect_pack(selectedOption.nom_packs_vols);
                                setPage(0);
                              }
                              if (reason === "clear") {
                                SetSelect_pack();
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Pack"
                                variant="standard"
                                id="Pack"
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell colSpan={2}>
                          <Autocomplete
                            id="Etat"
                            openOnFocus
                            options={filterEtat}
                            getOptionLabel={(e) => e.nom}
                            onChange={(event, selectedOption, reason) => {
                              if (selectedOption) {
                                SetSelect_etat(selectedOption.nom);
                                setPage(0);
                              }
                              if (reason === "clear") {
                                SetSelect_etat();
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Etat"
                                variant="standard"
                                id="Etat"
                              />
                            )}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" sx={{ top: 65 }}>
                          <b>complete nom</b>
                        </TableCell>

                        <TableCell align="center" sx={{ top: 65 }}>
                          <b>Pack</b>
                        </TableCell>
                        <TableCell align="center" sx={{ top: 65 }}>
                          <b>Etat</b>
                        </TableCell>
                        <TableCell align="center" sx={{ top: 65 }}>
                          <b>Prix Total</b>
                        </TableCell>
                        <TableCell align="center" sx={{ top: 65 }}>
                          <b>Prix Paye</b>
                        </TableCell>
                        <TableCell align="center" sx={{ top: 65 }}>
                          <b>Prix Reste</b>
                        </TableCell>

                        <TableCell align="center" sx={{ top: 65 }}>
                          <b>Action</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {resrevationOmra
                        ? resrevationOmra?.data?.map((r) => (
                            <TableRow
                              key={r.id}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                            >
                              <TableCell align="center">
                                {r?.nom_client}-{r?.prenom}
                              </TableCell>
                              <TableCell align="center">
                                {r?.nom_pack_omra}
                              </TableCell>
                              <TableCell align="center">{r?.etat}</TableCell>
                           
                              <TableCell align="center">
                                {r?.prix_total}
                              </TableCell>
                              <TableCell align="center">
                                {r.prix_total - r.prix_reste}
                              </TableCell>
                              <TableCell align="center">
                                {r?.prix_reste}
                              </TableCell>
                              <TableCell align="center">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <IconButton
                                    aria-label="pdf"
                                    color="default"
                                    size="small"
                                    onClick={() => {
                                      dispatch(PdfPackOmra(r.id));
                                    }}
                                  >
                                    <DownloadIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="info"
                                    color="secondary"
                                    size="small"
                                    onClick={() => {}}
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                  {r.etat !== "paiment Total" ? (
                                    <Link to={`paye/${r.id}`}>
                                      <IconButton
                                        aria-label="info"
                                        color="success"
                                        size="small"
                                      >
                                        <PaidIcon />
                                      </IconButton>
                                    </Link>
                                  ) : null}
                                </div>
                              </TableCell>
                            </TableRow>
                          ))
                        : Array.from({ length: 6 }, (_, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <TablePagination
                rowsPerPageOptions={[5, 25, 100]}
                component="div"
                count={resrevationOmra?.total ?? 1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {statistique_pack && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ width: "16%", textAlign: "center" }}>
                    <b>Pack</b>:{statistique_pack?.nom}
                  </p>
                  <p style={{ width: "16%", textAlign: "center" }}>
                    <b>place disponible :</b>
                    {statistique_pack?.place_disponible}
                  </p>
                  <p style={{ width: "16%", textAlign: "center" }}>
                    <b>place paye : </b>
                    {statistique_pack?.NB_place_reserve}
                  </p>
                  <p style={{ width: "16%", textAlign: "center" }}>
                    <b>prix total : </b>
                    {statistique_pack?.prix_total}
                  </p>
                  <p style={{ width: "16%", textAlign: "center" }}>
                    <b>prix paye : </b>
                    {statistique_pack?.prix_paye}
                  </p>
                  <p style={{ width: "16%", textAlign: "center" }}>
                    <b>prix reste : </b>
                    {statistique_pack?.prix_reste}
                  </p>
                </div>
              )}
            </Card>
          </Box>
        </Box>
      </Box>
      <BackLoading open={loading} />
    </div>
  );
}
