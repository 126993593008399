import { Card } from "@mui/material";
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { CreditCard } from "@mui/icons-material";




export default function Card3(){
    return(
        <Card sx={{background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(23,107,135,1) 49%, rgba(100,204,197,1) 100%);' , color:"white" , padding:"1%"}}>
        <h3> <CreditCard />  test</h3>
        </Card>
    )
}