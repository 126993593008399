import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../api/Api_token";
import download from "downloadjs";

export const get_Client_name = createAsyncThunk("get client", async () => {
  const response = await instance.get(`comptable/client/get_clients`);
  return response.data;
});

export const get_Pack_name = createAsyncThunk("get pack ", async () => {
  const response = await instance.get("comptable/reservation/get_packs_filter");
  return response.data;
});

export const get_reservation_comptable = createAsyncThunk(
  "get reservation",
  async (data) => {
    const response = await instance.get(
      `comptable/reservation/get_reservation/${data?.row}?page=${
        data?.page + 1
      }&pack=${data?.pack}&etat=${data?.etat}&client=${data?.client}`
    );
    return response.data;
  }
);

export const get_statisctique_pack = createAsyncThunk(
  "get statistique",
  async (pack) => {
    const response = await instance.get(`comptable/statistique_pack/${pack}`);
    return response.data;
  }
);

export const get_caisse = createAsyncThunk("get caisse", async () => {
  const response = await instance.get(`comptable/get_caisse`);
  return response.data;
});

export const get_type_payment = createAsyncThunk("get payment", async () => {
    const response = await instance.get(`comptable/get_type_payment`);
    return response.data;
  });

export const get_info_reservation = createAsyncThunk("get info" ,async (id)=>{
  const response = await instance.get(`comptable/reservation/get_info_reservation/${id}`)
  return response.data
})

export const paye_reservation= createAsyncThunk("payemnt" , async(data)=>{
   const response =await instance.post(`comptable/achate/achete_pack`,data)
   return response.data
})

export const fetchPdf = createAsyncThunk('fetchPdf', async (id, thunkAPI) => {
  try {
    const response = await instance.get(`comptable/achate/pdf/${id}`, {
      responseType: 'blob',
    });

    const contentType = response.headers['content-type']; // Get the content type from the response headers
    download(response.data, 'facture.pdf', contentType);
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }});

const initialState = {
  loading: false,
  clients: [],
  pack_filter: [],
  reservation: [],
  statistique_pack:{},
  caisse: [],
  type_payment:[],
  info_reservation:{}
  
};

export const Paye_slice = createSlice({
  name: "paye comptable",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(get_Client_name.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_Client_name.fulfilled, (state, action) => {
      state.loading = false;
      state.clients = action.payload;
    });
    builder.addCase(get_Client_name.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(get_Pack_name.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_Pack_name.fulfilled, (state, action) => {
      state.loading = false;
      state.pack_filter = action.payload;
    });
    builder.addCase(get_Pack_name.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(get_reservation_comptable.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_reservation_comptable.fulfilled, (state, action) => {
      state.loading = false;
      state.reservation = action.payload;
    });
    builder.addCase(get_reservation_comptable.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(get_statisctique_pack.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_statisctique_pack.fulfilled, (state, action) => {
      state.loading = false;
      state.statistique_pack = action.payload;
    });
    builder.addCase(get_statisctique_pack.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(get_caisse.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_caisse.fulfilled, (state, action) => {
      state.loading = false;
       state.caisse = action.payload
    });
    builder.addCase(get_caisse.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(get_type_payment.pending , (state , action)=>{
        state.loading = true
    })
    builder.addCase(get_type_payment.fulfilled , (state , action)=>{
        state.loading=false
        state.type_payment=action.payload
    })
    builder.addCase(get_type_payment.rejected , (state , action)=>{
        state.loading=false
    })
    builder.addCase(get_info_reservation.pending , (state , action)=>{
      state.loading=true
    })
    builder.addCase(get_info_reservation.fulfilled , (state , action)=>{
      state.loading=false
      state.info_reservation=action.payload
    })
    builder.addCase(get_info_reservation.rejected , (state , action)=>{
      state.loading = false
    })

   builder.addCase(paye_reservation.pending , (state , action)=>{
    state.loading = true
   })
   builder.addCase(paye_reservation.fulfilled , (state , action)=>{
    state.loading=false
   })
   builder.addCase(paye_reservation.rejected ,(state, action)=>{
    state.loading = false
   })
  },
});

export default Paye_slice.reducer;
