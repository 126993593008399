import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useState , useEffect } from "react";
import { MuiTelInput } from "mui-tel-input";
import { useDispatch  , useSelector} from "react-redux";
import { Auth_mailvalidate, Auth_register } from "../store/Auth";
import { useNavigate  } from "react-router-dom";
import CircularIndeterminate from "../pages/page_tous/loading";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      Your Website
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignUp() {
  const {loading_auth} = useSelector(state=>state.auth)
   const Navigate = useNavigate()
  const dispatch = useDispatch()
  const {t,i18n}=useTranslation()
  const [passwordError, SetPasswordError] = useState();
  const [emailError, setEmailError] = useState();
  const [numberError, setNumberError] = useState();

  useEffect(()=>{
    const userLanguage = navigator.language
    switch(userLanguage){
      case"fr-FR":
       i18n.changeLanguage("fr")
        break
      case "ar":
        i18n.changeLanguage("ar")
        break
      case "en" :
        i18n.changeLanguage("en")
        break
       default:    
        i18n.changeLanguage("en")  
    }
  },[])
  
  function vide_state(){
    setEmailError(false)
    setNumberError(false)
    SetPasswordError(false)
  }

  function isEmailValid(email) {
    // Utilisez une expression régulière pour valider l'e-mail
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  const handleSubmit = (event) => {
    
    event.preventDefault();
    const name = event.target.Name.value;
    const email = event.target.email.value;
    const password = event.target.password.value;
    const number = event.target.number.value;

    if (!isEmailValid(email)) {
      setEmailError(t("message_email"));
    
      return;
    }

    if (password.length < 8) {
      SetPasswordError(t("message_password"));
    
      return;
    }
    if (number.length < 10) {
      setEmailError(t("message_number"));
   
      return;
    }

    const data = {
      name: event.target.Name.value,
      email: event.target.email.value,
      password: event.target.password.value,
      numero: event.target.number.value,
    };

    dispatch(Auth_register(data)).then((resulat)=>{
      if(Auth_register.fulfilled.match(resulat)){
        if (resulat.payload.message == "create compte"){
             dispatch(Auth_mailvalidate(data))
            Navigate("/auth/valid")
        }
      }else{
           setEmailError(t("message_email2"))
      }
    })
   
  };

  if(loading_auth){
    return (<><CircularIndeterminate/></>)
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("inscrire")}
          </Typography>
          <Box
            component="form"
            required
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="Name"
                  required
                  fullWidth
                  id="Name"
                  label={t("name")}
                  autoFocus
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  error={emailError} 
                  helperText={emailError}
                  fullWidth
                  id="email"
                  label={t("Email_Address")}
                  name="email"
                  autoComplete="email"
                 
                />
            
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  error={numberError}
                  helperText={numberError}
                  id="number"
                  label={t("number_phone")}
                  name="number"
                  type="number"
                  autoComplete="number"
                  
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  error={passwordError}
                  helperText={passwordError}
                  name="password"
                  label={t("password")}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("inscrire")}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item></Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
