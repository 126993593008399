import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Box, FormControlLabel, Grid } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { Button } from "@mui/material";
import { filteretats } from "../../../data/vol_data";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Vols_client from "../../../pages/client/Vols_client";
import { Vols_clients } from "../../../store/client/Vols_client_slice";
import {
  get_pack_omra,
  get_pack_omra_chamber,
} from "../../../store/commerciel/reservation_omra _slice";
import Checkbox from "@mui/material/Checkbox";
import { json } from "react-router-dom";

export default function Reserevation_dialoge_pack_omra({
  open,
  onClose,
  onlick,
}) {
  const dispach = useDispatch();
  useEffect(() => {
    dispach(get_pack_omra());
  }, []);

  return (
    <Box>
      <Dialog open={open} onClose={onClose} fullScreen>
        <DialogTitle sx={{ fontSize: "36px" }}>Ajouter reservation</DialogTitle>
        <Form_reservation send={onlick} onclose={onClose} />
      </Dialog>
    </Box>
  );
}

export function Form_reservation({ send, onclose }) {
  const filteretat = filteretats;
  const { pack_omra } = useSelector((state) => state.resrvation_omra);
  const { chamber } = useSelector((state) => state.resrvation_omra);
  const [etat, setEtat] = useState();
  const [pack, setpack] = useState();
  const [id_pack, setIdPack] = useState();
  const [erreurEmail, setErreurEmail] = useState();
  const [double, setDouble] = useState(false);
  const [tri, setTri] = useState(false);
  const [Quad, SetQuad] = useState(false);
  const [Cinq, setCinq] = useState(false);
  const [ErreurTele, SetErreurTele] = useState();
  const [number, SetNumber] = useState();
  const [ErreurPlace, seterreurPlace] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_pack_omra_chamber(id_pack));
  }, [id_pack]);
  function isEmailValid(email) {
    // Utilisez une expression régulière pour valider l'e-mail
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  function inisal_erreur() {
    setErreurEmail();
    SetErreurTele();
    seterreurPlace();
  }

  function inisal_state() {
    setEtat();
    setpack();
    SetNumber();
    inisal_erreur();
  }
  function send_Data(e) {
    e.preventDefault();
    inisal_erreur();
    const data = e.target;
    const telephone = data.Telephone.value;

    if (!isEmailValid(data.Email_client.value)) {
      setErreurEmail("Adresse e-mail invalide");
      return;
    }
    if (telephone.length < 10) {
      SetErreurTele("le numero telephone moin que 10");
      return;
    }
    const formData = new FormData();

    formData.append("nom_client", data.nom_client.value);
    formData.append("prenom_client", data.prenom_client.value);
    formData.append("email", data.Email_client.value);
    formData.append("telephone", telephone);
    formData.append("remarque", data.remarque.value);
    formData.append("pack", pack);
    formData.append("pack_omra_id", id_pack);
    formData.append("ice", data.ice.value);
    formData.append("societe", data.societe.value);
    formData.append("adress", data.adress.value);
    formData.append("date_expiration", data.date.value);
    const array = [];
    var prix = 0;

    if (double) {
      prix = prix + chamber[0].prix * data.double.value;
      array.push({ id: 1, place: parseInt(data.double.value) , id_chamber:chamber[0].id_chamber });
    }

    if (tri) {
      prix = prix + chamber[1].prix * data.tri.value;
      array.push({ id: 2, place: parseInt(data.tri.value) ,id_chamber:chamber[1].id_chamber});
    }

    if (Quad) {
      prix = prix + chamber[2].prix * data.quad.value;
      array.push({ id: 3, place: parseInt(data.quad.value),id_chamber:chamber[2].id_chamber });
    }

    if (Cinq) {
      prix = prix + chamber[3].prix * data.cinq.value;
      array.push({ id: 4, place: parseInt(data.cinq.value),id_chamber:chamber[3].id_chamber });
    }
   console.log(array)
    formData.append("prix_total", prix);
    formData.append("type_chamber", JSON.stringify(array));
    send(formData);
    inisal_state();
  }
  return (
    <Box
      component="form"
      onSubmit={send_Data}
      sx={{ width: "60%", marginLeft: "20%" }}
    >
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <h2>info Client :</h2>
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Nom client"
            name="nom_client"
            autoComplete="Name Client"
            autoFocus
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Prenom client"
            name="prenom_client"
            autoComplete="Prenom Client"
            autoFocus
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email"
            name="Email_client"
            autoComplete="Email Client"
            error={erreurEmail ? true : false}
            helperText={erreurEmail ? `${erreurEmail}` : ""}
            autoFocus
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            type="number"
            label="Telephone Client"
            name="Telephone"
            autoComplete="Telephone Client"
            error={ErreurTele ? true : false}
            helperText={ErreurTele ? `${ErreurTele}` : " "}
            autoFocus
            inputProps={{
              maxLength: 15,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField required fullWidth label="Ice" autoFocus name="ice" />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            fullWidth
            label="Societe"
            autoFocus
            name="societe"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Adress"
            autoFocus
            name="adress"
          />
        </Grid>
        <Grid item xs={12}>
          <h2>info Pack :</h2>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            sx={{ marginTop: "3%" }}
            id="pack"
            options={pack_omra}
            getOptionLabel={(p) => p.nom_pack_omra}
            openOnFocus
            isOptionEqualToValue={(option, value) => option.type === value.type}
            onChange={(event, selectedOption, reason) => {
              if (selectedOption) {
                setpack(selectedOption.nom_pack_omra);
                setIdPack(parseInt(selectedOption.id));
              }
              if (reason === "clear") {
                setpack("");
                setIdPack();
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="pack"
                variant="outlined"
                required
                id="pack"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            sx={{ marginTop: "3%" }}
            type="date"
            name="date"
            required
            fullWidth
            variant="outlined"
            label="Date d'expiration"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        {id_pack && chamber ? (
          <>
            <Grid item xs={12}>
              <h2>Option :</h2>
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={double}
                    onChange={(e) => {
                      setDouble(e.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={<h3>Double</h3>}
              />
            </Grid>
            <Grid item xs={4}>
              <p>place disponible {chamber[0]?.place}</p>
            </Grid>
            <Grid item xs={4}>
              <TextField
                required={double}
                fullWidth
                type="number"
                label="Number ticket double "
                name="double"
                autoComplete="Number ticket double"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={tri}
                    onChange={(e) => {
                      setTri(e.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={<h3>Tri</h3>}
              />
            </Grid>
            <Grid item xs={4}>
              <p>place disponible {chamber[1]?.place}</p>
            </Grid>
            <Grid item xs={4}>
              <TextField
                required={tri}
                fullWidth
                type="number"
                label="Number ticket Tri"
                name="tri"
                autoComplete="Number ticket tri"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Quad}
                    onChange={(e) => {
                      SetQuad(e.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={<h3>Quad</h3>}
              />
            </Grid>
            <Grid item xs={4}>
              <p>place disponible {chamber[2]?.place}</p>
            </Grid>
            <Grid item xs={4}>
              <TextField
                required={Quad}
                fullWidth
                type="number"
                label=" Number ticket Quad "
                name="quad"
                autoComplete="Number ticket Quad "
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Cinq}
                    onChange={(e) => {
                      setCinq(e.target.checked);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={<h3>Cinq</h3>}
              />
            </Grid>
            <Grid item xs={4}>
              <p>place disponible {chamber[3]?.place}</p>
            </Grid>
            <Grid item xs={4}>
              <TextField
                required={Cinq}
                fullWidth
                type="number"
                label="Number ticket Cinq"
                name="cinq"
                autoComplete="Number ticket Cinq"
              />
            </Grid>
          </>
        ) : null}
        <Grid item xs={12}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Remarque"
            name="remarque"
            autoComplete="Remarque"
          />
        </Grid>
      </Grid>
      <div style={{ position: "fixed", bottom: 30, right: 30 }}>
        <Button onClick={onclose}>Annuler</Button>

        <Button variant="contained" color="primary" type="submit">
          ajouter
        </Button>
      </div>
    </Box>
  );
}
