import { useEffect } from "react";
import Navbar from "../../components/Appbar";
import MiniDrawer from "../../components/draw";
import { Box, Card, Grid } from "@mui/material";
import { updateAuthToken } from "../../api/Api_token";
import { useSelector } from "react-redux";
import Card1 from "../../model/dashbord/Card1";
import Card2 from "../../model/dashbord/Card2";
import Card3 from "../../model/dashbord/Card3";
import Card4 from "../../model/dashbord/Card4";
import ListPack from "../../model/dashbord/listPack";

export default function HomeAdmin() {
  useEffect(() => {}, []);
  return (
    <div style={{ background: "#EEEEEE", minHeight: "100vh" }}>
      <Navbar />
      <Box sx={{ display: "flex" }}>
        <MiniDrawer />
        <Box
          component="main"
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "90px",
            width: "100%",
          }}
        >
          <DahbordAdmin />
        </Box>
      </Box>
    </div>
  );
}

export function DahbordAdmin() {
  return (
    <div>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding="1%">
        <Grid item xs={8}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
             <Card1/>
            </Grid>
            <Grid item xs={6}>
              <Card2/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
         <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} height="100%">
          <Grid item xs={12}  >
             <Card3/>
          </Grid>
          <Grid item xs={12}>
            <Card4/>
          </Grid>
         </Grid>
        </Grid>
        <Grid item xs={8}>
          <Card>
            <h1>test</h1>
          </Card>
          
        </Grid>
        <Grid item xs={4}>
         <ListPack/>
        </Grid>
      </Grid>
    </div>
  );
}
