import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {instance} from "../../api/Api_token";


export  const New_vol = createAsyncThunk("new vol" , async(data)=>{
       const response = instance.post(`admin/vol/new_vol`,data)
       return response
})

export const get_Vols = createAsyncThunk("get_vols", async (data) => {
      const response = await instance.get(`admin/vol/vols/${data.num}?page=${data.page+1}`);
      return response.data; 
    
  });

export const delete_vol = createAsyncThunk("delete vol" , async(id)=>{
     const response = await instance.delete(`admin/vol/delete_vol/${id}`)
     return response.data
})



const initialState = {
     loading:false,
     vols:[],
     origines:[],
     destinations:[],
    
   };

export const vol_slice = createSlice({
  name: "vols",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
       builder.addCase(New_vol.pending , (state, action)=>{
          state.loading=true
       })
       builder.addCase(New_vol.fulfilled , (state,action)=>{
        state.loading=false
       })
       builder.addCase(New_vol.rejected , (state,action)=>{
        state.loading=false
    })

    builder.addCase(get_Vols.pending , (state,action)=>{
        state.loading=true
    })
    builder.addCase(get_Vols.fulfilled , (state, action)=>{
        state.loading=false
        state.vols=action.payload
    })
    builder.addCase(get_Vols.rejected , (state , action)=>{
        state.loading =false
    })

    builder.addCase(delete_vol.pending , (state, action)=>{
      state.loading=true
   })
   builder.addCase(delete_vol.fulfilled , (state,action)=>{
    state.loading=false
   })
   builder.addCase(delete_vol.rejected , (state,action)=>{
    state.loading=false
})
  },
});

export default vol_slice.reducer;
