import Navbar from "../../components/Appbar";
import MiniDrawer from "../../components/draw";
import { Box, Card, IconButton, Paper, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableContainer } from "@mui/material";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { Aj_up_vol } from "../../model/vol/aj_up_vol";
import TextFieldSlotProps from "../../model/Date";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { New_vol ,delete_vol,get_Vols } from "../../store/admin/vol_slice";
import SuccesDialoge from "../../model/succes";
import ErreurDialoge from "../../model/Erreur";
import { TablePagination } from "@mui/material";
import BackLoading from "../page_tous/back_loading";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import { Skeleton } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteConfirmationDialog from "../../model/DeleteConfirme";

import { New_pack, delete_pack, get_pack } from "../../store/admin/Pack_Vols_slice";
import { Aj_up_pack } from "../../model/pack_vols/Aj_up_pack";
import Add_pack from "../../model/pack_vols/ajouter_pack";

export default function Pack_vols() {
  

  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [DialogAjouter, SetDialogAjouter] = useState(false);
  const [SuccesAjouter, SetSuccesAjouter] = useState(false);
  const [ErreurAjouter, SetErreurAjouter] = useState(false);
  const [origine, SetOrigine] = useState([]);
  const [destination, setdestination] = useState([]);
  const [date_depart, SetDate_depart] = useState();
  const [date_arrive, SetDate_arrive] = useState();
  const [id_delete,setId_delete] = useState()
  const [dialog_delete , SetDialog_delete]= useState(false)
  const [succesdelete ,SetSuccesDelete]=useState(false)
  const [erreurDelete , setErreurdelete]=useState(false)
  const  {pack}=useSelector((state)=>state.pack)
  const  {loading}=useSelector((state)=>state.pack)

  useEffect(() => {
    get_pack__()
}, [page , rowsPerPage]);



function get_pack__(){
 const data ={
   page: page,
   num: rowsPerPage,
 }
 dispatch(get_pack(data))

}
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function inisial_recherch() {
    const dateActuelle = new Date();
    SetDate_depart(dateActuelle); 
    SetDate_arrive(dateActuelle); 
  }
  // ajouter vol  state

  // function ajouter vol
  function Ajouter(data) {


    SetDialogAjouter(false);
    dispatch(New_pack(data)).then((result) => {
      if (New_pack.fulfilled.match(result)) {
        SetSuccesAjouter(true);
      }
      if (New_pack.rejected.match(result)) {
        SetErreurAjouter(true);
      }
    });
  }

 function supprimer_pack(){
  SetDialog_delete(false);
  dispatch(delete_pack(id_delete)).then((result) => {
    if (delete_pack.fulfilled.match(result)) {
      SetSuccesDelete(true);
    }
    if (delete_pack.rejected.match(result)) {
      setErreurdelete(true);
    }
  });
  }


  return (
    <div style={{ background: "#EEEEEE", minHeight: "100vh" }}>
      <Navbar />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <MiniDrawer />
        <Box
          component="main"
          sx={{
            marginTop: "7%",
            width: "100%",
          }}
        >
          <Box
            component="main"
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "3%",
              marginLeft: "3%",
            }}
          >
            <Card>
              <Paper>
                <TableContainer sx={{ maxHeight: 440 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          <Typography variant="h4">
                            <b>Pack Vols</b>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Autocomplete
                            id="Origine"
                            openOnFocus
                            options={origine}
                            onChange={(event, selectedOption, reason) => {
                              if (selectedOption) {

                              }
                              if (reason === "clear") {
                          
                               // dispatch(get_destination());
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Origine"
                                variant="standard"
                                id="type"
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Autocomplete
                            id="destination"

                             options={destination}


                            openOnFocus
                            onChange={(event, selectedOption, reason) => {
                              if (selectedOption) {

                              }
                              if (reason === "clear") {
                            
                              }
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="destination"
                                variant="standard"
                                id="type"
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                          variant="standard"
                          label="date deaprt"
                          InputLabelProps={{
                            shrink: true,
                          }}
                            type="date"
                           
                            value={date_depart}
                            onChange={(e) => {
                              SetDate_depart(e.target.value);
                            }}
                          />
                        </TableCell>
                        <TableCell>
                        <TextField
                            variant="standard"
                            label="date arrive"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="date"
                            value={date_arrive}
                            onChange={(e) => {
                              SetDate_arrive(e.target.value);
                            }}
                          />
                        </TableCell>

                        <TableCell>
                          <IconButton onClick={inisial_recherch}>
                            <DisabledByDefaultOutlinedIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            onClick={() => {
                              SetDialogAjouter(true);
                            }}
                          >
                            +add
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>Name</b>
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>Origine</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>destination</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>date depart</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>prix</b>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>place</b>
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ width: "18%", top: 65 }}
                        >
                          <b>action</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pack
                        ? pack?.data?.map((v) => (
                            <TableRow
                              key={v.id}
                              hover
                              role="checkbox"
                              tabIndex={-1}
                            >
                              <TableCell align="center">{v?.nom_packs_vols}</TableCell>
                              <TableCell align="center">{v?.airport_aller}</TableCell>
                              <TableCell align="center">{v?.airport_destination}</TableCell>
                              <TableCell align="center">{v?.date_aller}</TableCell>
                              <TableCell align="center">{v?.prix}</TableCell>
                              <TableCell align="center">{v?.place}</TableCell>
                              <TableCell align="center">
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <IconButton
                                    aria-label="info"
                                    color="secondary"
                                    size="small"
                                    
                                  >
                                    
                                    <InfoIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="update"
                                    color="primary"
                                    size="small"
                                  >
                                    <ModeEditOutlineIcon />
                                  </IconButton>
                                  <IconButton
                                    aria-label="delete"
                                    size="small"
                                    color="error"
                                    onClick={()=>{ setId_delete(v.id) ; SetDialog_delete(true)}}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))
                        : Array.from({ length: 6 }, (_, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <Skeleton
                                  variant="text"
                                  sx={{ fontSize: "1rem" }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>

                  </Table>
                </TableContainer>
              </Paper>
              <TablePagination
              rowsPerPageOptions={[5, 25, 100]}
              component="div"
              count={pack?.total ?? 1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </Box>
        </Box>
      </Box>
      <Add_pack
        open={DialogAjouter}
        onClose={()=>{SetDialogAjouter(false)}}
        onlik={Ajouter}
      />
      <SuccesDialoge
        open={SuccesAjouter}
        onClose={() => {
          SetSuccesAjouter(false);
          get_pack__()
        }}
        content="pack vol ajouter avec succes"
      />
      <ErreurDialoge
        open={ErreurAjouter}
        onClose={() => {
          SetErreurAjouter(false);
        }}
        content="Échec de l'ajouter du pack vol"
      />
      <DeleteConfirmationDialog 
        open={dialog_delete}
        onClose={() => {
          SetDialog_delete(false);
          setId_delete();
        }}
        onConfirm={supprimer_pack}
        content="Êtes-vous sûr de vouloir supprimer ce pack ?"
      />
       <SuccesDialoge
        open={succesdelete}
        onClose={() => {
          SetSuccesDelete(false);
         get_pack__()
        }}
        content=" pack supprimer avec succes"
      />
      <ErreurDialoge
        open={erreurDelete}
        onClose={() => {
          setErreurdelete(false);
        }}
        content="Échec de supprimer du pack"
      />


      <BackLoading open={loading} />
    </div>
  );
}
