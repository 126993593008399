import { deconnection } from "./Auth";

const customMiddleware = store => next => action => {
    if (action.type.endsWith('/rejected')) {
      if (action.error.message=== "Request failed with status code 401") {
        store.dispatch(deconnection());
      }
    }
    return next(action);
  };
  
  export default customMiddleware;