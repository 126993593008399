import Navbar from "../../components/Appbar";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MiniDrawer from "../../components/draw";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useEffect, useState } from "react";
import { etatsData } from "../../data/vol_data";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPdf,
  get_caisse,
  get_info_reservation,
  get_type_payment,
  paye_reservation,
} from "../../store/comptable/paye_slice";
import BackLoading from "../page_tous/back_loading";
import { useParams } from "react-router-dom";
import SuccesDialoge from "../../model/succes";
import ErreurDialoge from "../../model/Erreur";
import { PDFDownloadLink } from "@react-pdf/renderer";
import FacturePackVols from "../../model/pdf/pack_vols_facture";

export default function Pack_vols_paye() {
  const { id } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    get_data();
  }, []);
  function get_data() {
    dispatch(get_info_reservation(id));
  }
  const { info_reservation } = useSelector((state) => state.paye);
  const { loading } = useSelector((state) => state.paye);
  return (
    <div style={{ background: "#EEEEEE", minHeight: "100vh" }}>
      <Navbar />
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <MiniDrawer />
        <Box
          component="main"
          sx={{
            marginTop: "7%",
            width: "100%",
          }}
        >
          <Box
            component="main"
            sx={{
              display: "flex",
              flexDirection: "column",
              marginRight: "3%",
              marginLeft: "3%",
            }}
          >
            <Page_payment id={id} dt={get_data} />
          </Box>
        </Box>
      </Box>
      <BackLoading open={loading} />
    </div>
  );
}

export function Page_payment({ id, dt }) {
  const { info_reservation } = useSelector((state) => state.paye);
  const dispatch = useDispatch();
  const [succes, Setsucces] = useState(false);
  const [error, setError] = useState(false);

  function payment(data) {
    data.append("id", id);
    dispatch(paye_reservation(data)).then((result) => {
      if (paye_reservation.fulfilled.match(result)) {
        Setsucces(true);
        dispatch(fetchPdf(id));
      }
      if (paye_reservation.rejected.match(result)) {
        setError(true);
      }
    });
  }
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={8}>
          <Grid container rowSpacing={3}>
            <Grid xs={12} item>
              <Info_reservation data={info_reservation} />
            </Grid>
            <Grid item xs={12}>
              <Operation_payment func={payment} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Info_personnel data={info_reservation} />
        </Grid>
      </Grid>
      <SuccesDialoge
        open={succes}
        onClose={() => {
          Setsucces(false);
          dt();
        }}
        content={<h3>paiement effectué</h3>}
      />
      <ErreurDialoge
        open={error}
        onClose={() => {
          setError(false);
        }}
        content="paiement  non effectué"
      />
    </>
  );
}

export function Info_personnel({ data }) {
  return (
    <>
      <Card>
        <Paper>
          <Typography
            variant="h4"
            sx={{ textAlign: "center", marginTop: "5%", marginBottom: "10%" }}
          >
            Info personnel
          </Typography>
        </Paper>

        <Typography variant="h6" sx={{ marginTop: "10%", marginBottom: "5%" }}>
          Nom :{data?.nom_client}
        </Typography>

        <Typography variant="h6" sx={{ marginTop: "10%", marginBottom: "5%" }}>
          Prenom :{data?.prenom}
        </Typography>
        <Typography variant="h6" sx={{ marginTop: "10%", marginBottom: "5%" }}>
          Telephone :{data?.telephone}
        </Typography>
        <Typography variant="h6" sx={{ marginTop: "10%", marginBottom: "5%" }}>
          Email :{data?.email}
        </Typography>
      </Card>
      <Card>
        <Paper>
          <Typography
            variant="h4"
            sx={{ textAlign: "center", marginTop: "5%", marginBottom: "10%" }}
          >
            Info reservation
          </Typography>
        </Paper>

        {data?.place_reserve && (
          <Typography
            variant="h6"
            sx={{ marginTop: "10%", marginBottom: "5%" }}
          >
            place reserve :{data?.place_reserve} place
          </Typography>
        )}

        <Typography variant="h6" sx={{ marginTop: "10%", marginBottom: "5%" }}>
          remarque :{data?.remarque}
        </Typography>
      </Card>
    </>
  );
}

export function Info_reservation({ data }) {
  return (
    <Card>
      <Paper>
        <Typography variant="h4" sx={{ textAlign: "center", marginTop: "2%" }}>
          info paiement
        </Typography>
      </Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <b>Pack :</b> {data?.nom_packs_vols}
            </TableCell>
            <TableCell>
              <b>Prix total :</b> {data?.prix_total} dh
            </TableCell>
            <TableCell>
              <b>Prix paye</b> {data.prix_total - data.prix_reste} dh
            </TableCell>
            <TableCell>
              <b>Prix reste :</b> {data?.prix_reste} dh
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </Card>
  );
}

export function Operation_payment({ func }) {
  const dispatch = useDispatch();
  const etats = etatsData;
  const { caisse } = useSelector((state) => state.paye);
  const { type_payment } = useSelector((state) => state.paye);
  const [id_caisse, SetId_caisse] = useState();
  const [id_payment, setId_payment] = useState();
  const [payment, setPayment] = useState();
  const [select_etat, setSelect_etat] = useState();
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    dispatch(get_caisse());
    dispatch(get_type_payment());
  }, []);

  function paye(e) {
    e.preventDefault();
    const data = new FormData();
    data.append("id_caisse", id_caisse);
    data.append("id_type_payment", id_payment);
    data.append("etat", select_etat);
    data.append("prix_paye", e.target.prix_paye.value);
    data.append("date", e.target.date.value);
    if (payment === "cheque") {
      data.append("num_cheque", e.target.numero_cheque.value);
      data.append("bank", e.target.bank.value);
      data.append("nom_personne_cheque", e.target.Nom_personne.value);
      data.append("date_cheque", e.target.date_cheque.value);

      data.append("image", e.target.file.files[0]);
    }
    if (payment === "virement" || payment === "versement") {
      data.append("image", e.target.file.files[0]);
      data.append("bank", e.target.bank.value);
    }
    func(data);
  }
  return (
    <Card>
      <Paper sx={{ padding: "3%" }}>
        <Typography variant="h4" textAlign="center">
          Operation de paiement
        </Typography>
      </Paper>
      <Box component="form" onSubmit={paye} encType="multipart/form-data">
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ padding: "5%" }}
        >
          <Grid item xs={6}>
            <Autocomplete
              id="caisse"
              openOnFocus
              options={caisse}
              getOptionLabel={(c) => c.nom}
              isOptionEqualToValue={(option, value) => option.id === id_caisse}
              onChange={(event, selectedOption, reason) => {
                if (selectedOption) {
                  SetId_caisse(selectedOption.id);
                }
                if (reason === "clear") {
                  SetId_caisse();
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Caisse"
                  variant="outlined"
                  id="caisse"
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="Type_payment"
              openOnFocus
              options={type_payment}
              getOptionLabel={(t) => t.type}
              onChange={(event, selectedOption, reason) => {
                if (selectedOption) {
                  setId_payment(selectedOption.id);
                  setPayment(selectedOption.type);
                }
                if (reason === "clear") {
                  setId_payment();
                  setPayment();
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Type payment"
                  variant="outlined"
                  id="Type_payment"
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              id="Etat"
              openOnFocus
              options={etats}
              getOptionLabel={(e) => e.nom}
              onChange={(event, selectedOption, reason) => {
                if (selectedOption) {
                  setSelect_etat(selectedOption.nom);
                }
                if (reason === "clear") {
                  setSelect_etat();
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Etat"
                  variant="outlined"
                  id="Etat"
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Prix paye"
              type="number"
              variant="outlined"
              name="prix_paye"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="date"
              InputLabelProps={{
                shrink: true,
              }}
              type="date"
              variant="outlined"
              name="date"
              fullWidth
              required
            />
          </Grid>

          {payment === "cheque" ? (
            <>
              <Grid item xs={12}>
                <Typography variant="h5"> Information cheque :</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="nom personne"
                  name="Nom_personne"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="bank"
                  name="bank"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="numero cheque"
                  name="numero_cheque"
                  variant="outlined"
                  fullWidth
                  required
                  type="number"
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  label="date cheque"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  variant="outlined"
                  name="date_cheque"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  component="label"
                  variant="outlined"
                  startIcon={<CloudUploadIcon />}
                  sx={{ width: "100%" }}
                >
                  Upload File
                  <input
                    name="file"
                    type="file"
                    required
                    // accept=".pdf,.jpg,.png"
                    onChange={(e) => {
                      setSelectedFile(e.target.files[0]);
                    }}
                    style={{ display: "none" }}
                  />
                </Button>
                {selectedFile && <p>Selected File: {selectedFile.name}</p>}
              </Grid>
            </>
          ) : null}
          {payment === "virement" || payment === "versement" ? (
            <>
              <Grid item xs={12}>
                <Typography variant="h5"> Information {payment}:</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="bank"
                  name="bank"
                  variant="outlined"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  component="label"
                  variant="outlined"
                  startIcon={<CloudUploadIcon />}
                  sx={{ width: "100%" }}
                >
                  Upload File
                  <input
                    name="file"
                    type="file"
                    required
                    accept=".pdf,.jpg,.png"
                    onChange={(e) => {
                      setSelectedFile(e.target.files[0]);
                    }}
                    style={{ display: "none" }}
                  />
                </Button>
                {selectedFile && <p>Selected File: {selectedFile.name}</p>}
              </Grid>
            </>
          ) : null}
          <Grid item xs={12}>
            <Button variant="contained" type="submit">
              paye
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}
